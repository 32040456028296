<!-- 质检 -->
<template>
  <div
    v-loading="loading"
    element-loading-text="数据较多，拼命加载中..."
    class=""
  >
    <!-- <div
          v-if="navBarFixed2"
          :class="['row9', navBarFixed2 ? 'navBarWrap' : '']"
        >
          <div class="col publicColTit">
            <img src="@/assets/images/seviceIcon.png" />
            <span class="publicTotal">质检数据统计</span>
          </div>
        </div> -->
    <div class="row9">
      <div class="col publicColTit">
        <!-- <i class="iconfont icon-zhijian"></i> -->
        <span class="publicTotal">质检数据统计</span>
      </div>
    </div>
    <div class="row10">
      <div v-loading="loading4"
    element-loading-text="数据较多，拼命加载中..." class="col publicBox">
        <div class="tit tit2">
          质检店铺率统计
          <div>
            <el-radio-group v-model="label" @change="changeRadio0">
              <el-radio-button :label="0">昨日</el-radio-button>
              <el-radio-button :label="1">今日</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <div class="con">
          <el-progress
            type="circle"
            :percentage="line4? area4.qualityedShopNum&&area4.shopNum ? ((area4.qualityedShopNum / area4.shopNum) * 100 > 100 ? 100 : (area4.qualityedShopNum / area4.shopNum) * 100):0
            :area.qualityedShopNum&&area.shopNum ? ((area.qualityedShopNum / area.shopNum) * 100 > 100 ? 100 : (area.qualityedShopNum / area.shopNum) * 100):0"
            :width="288"
            :stroke-width="30"
            :format="format"
          ></el-progress>
          <div
            class="year"
            style="display: flex;flex-direction: column;justify-content: center;"
          >
            <span class="total">服务店铺数（淘系）</span>
            <el-button
              @click="
                () => {
                  collect(
                    'shopNum',
                    '服务店铺数',
                    [
                      { name: '店铺名称', type: 'input', modelKey: 'shopName' },

                      {
                        name: '所属客户',
                        type: 'customerScreen',
                        modelKey: 'customerId',
                        selOpt: rolesCompany
                      }
                    ],
                    [
                      { name: '店铺名称', colProp: ['shopName'] },
                      { name: '所属公司', colProp: ['cpmpanyName'] },
                      { name: '所属客户', colProp: ['customerName'] },
                      { name: '累计质检次数', colProp: ['qualityCount'] }
                    ],
                    [this.datePicker0[0],this.datePicker0[1]]
                  );
                }
              "
              type="text"
              class="text"
              >{{ line4? (area4.shopNum || 0): area.shopNum || 0 }}</el-button
            >
            <span class="total tot2">质检店铺数</span>
            <el-button @click="() => { collect(
                'qualityedShopNum',
                '质检店铺数',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  
                  { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: rolesCompany },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属公司', colProp: ['cpmpanyName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '累计质检次数', colProp: ['qualityCount'] },
                ],
                [this.datePicker0[0],this.datePicker0[1]]
              )}" type="text" class="text2">{{
              line4? (area4.qualityedShopNum || 0): (area.qualityedShopNum || 0)
            }}</el-button>
          </div>
        </div>
      </div>
      <div  v-loading="loading5" class="col publicBox">
        <div class="tit tit2">
          质检店铺数统计
          <div>
            <el-radio-group v-model="label2" @change="changeRadio00">
              <el-radio-button :label="0">昨日</el-radio-button>
              <el-radio-button :label="1">今日</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <div style="display: flex;align-items: center;">
          <!-- 图表 -->
          <div class="pie">
            <!-- echarts -->
            <!-- 图表 -->
            <!-- 圆环中心大标题 圆环中心小标题 类名 颜色 data -->
            <pie-charts
              v-if="flag && flag5"
              :centerTxt="line5? (area5.shouldQualityShopNum + '' || '0'):(area.shouldQualityShopNum + '' || '0')"
              :subTxt="'应质检店铺数'"
              :pieName="'main4'"
              :pieColor="['rgb(82, 196, 26)', 'rgb(240, 144, 9)']"
              :pieData="[
                { value: line5? area5.qualityedShopNum:area.qualityedShopNum, name: '实际质检店铺数' },
                { value: line5? area5.unQualityShopNum:area.unQualityShopNum, name: '未质检店铺数' }
              ]"
              :selectDate="[this.datePicker00[0],this.datePicker00[1]]"
              :comId="comId"
            ></pie-charts>
          </div>
          <div
            class="year"
            style="display: flex;flex-direction: column;justify-content: center;"
          >
            <el-tooltip
                class="item"
                effect="dark"
                content="每日实际质检店铺数，根据日期筛选实际质检店铺数"
                placement="bottom"
                enterable
                >
            <span class="total">实际质检店铺数<img
                    src="@/assets/images/question.png"
                    class="tit-img"/></span>
            </el-tooltip>
            <el-button @click="() => { collect(
                'qualityedShopNum',
                '实际质检店铺数',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  
                  { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: rolesCompany },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属公司', colProp: ['cpmpanyName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '累计质检次数', colProp: ['qualityCount'] },
                ],
                [this.datePicker00[0],this.datePicker00[1]]
              )}" type="text" class="text shop2">{{
               line5? (area5.qualityedShopNum || 0): (area.qualityedShopNum || 0)
            }}</el-button>
            <el-tooltip
                class="item"
                effect="dark"
                content="未质检店铺数=应质检店铺数—实际质检店铺数"
                placement="bottom"
                enterable
                >
            <span class="total topPx">未质检店铺数<img
                    src="@/assets/images/question.png"
                    class="tit-img"/></span>
            </el-tooltip>
            <el-button @click="() => { collect(
                'unQualityShopNum',
                '未质检店铺数',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  
                  { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: rolesCompany },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属公司', colProp: ['cpmpanyName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '累计质检次数', colProp: ['qualityCount'] },
                ],
                [this.datePicker00[0],this.datePicker00[1]]
              )}" type="text" class="text shop3">{{
              line5? (area5.unQualityShopNum || 0):(area.unQualityShopNum || 0)
            }}</el-button>
          </div>
        </div>
      </div>
      <div class="col publicThreeBox">
        <div class="tit">
          质检数据概览
        </div>
        <ul style="width: 100%;">
          <li style="display: flex;">
            <span class="everyone"
              ><span class="sale-tit">质检人力</span
              ><el-button @click="
                () => {
                  collect(
                    'manpowerNum',
                    '质检人力',
                    [
                      {
                        name: '质检人员',
                        type: 'input',
                        modelKey: 'adminName'
                      }
                    ],
                    [
                      { name: '质检人员', colProp: ['nickName'] },
                      { name: '累计质检次数', colProp: ['qualityCount'] },
                      { name: '最近质检时间', colProp: ['qualityTime'] }
                    ]
                  );
                }
              " type="text" class="sale-datas">{{
                area.manpowerNum || 0
              }}</el-button></span
            >
            <span class="everyone"
              ><span class="sale-tit">人均质检量</span
              ><el-button @click="
                () => {
                  collect(
                    'avgQualityNum',
                    '人均质检量',
                    [
                      {
                        name: '质检人员',
                        type: 'input',
                        modelKey: 'adminName'
                      }
                    ],
                    [
                      { name: '质检人员', colProp: ['nickName'] },
                      { name: '累计质检次数', colProp: ['qualityCount'] },
                      { name: '最近质检时间', colProp: ['qualityTime'] }
                    ]
                  );
                }
              " type="text" class="sale-datas">{{
                area.avgQualityNum || 0
              }}</el-button></span
            >
            <span class="everyone  ever2"
              ><span class="sale-tit">今日新增质检次数</span
              ><el-button @click="() => { collect(
                'todayNum',
                '今日新增质检次数',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  
                  { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: rolesCompany },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属公司', colProp: ['cpmpanyName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '今日质检次数', colProp: ['qualityCount'] },
                ]
              )}" type="text" class="sale-datas">{{
                area.todayNum || 0
              }}</el-button></span
            >
          </li>
          <li style="display: flex;">
            <span class="everyone  ever2"
              ><span class="sale-tit">昨日新增质检次数</span
              ><el-button @click="() => { collect(
                'yesterdayNum',
                '昨日新增质检次数',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  
                  { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: rolesCompany },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属公司', colProp: ['cpmpanyName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '昨日质检次数', colProp: ['qualityCount'] },
                ]
              )}" type="text" class="sale-datas">{{
                area.yesterdayNum || 0
              }}</el-button></span
            >
            <span class="everyone  ever2"
              ><span class="sale-tit">本周新增质检次数</span
              ><el-button @click="() => { collect(
                'weekNum',
                '本周新增质检次数',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  
                  { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: rolesCompany },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属公司', colProp: ['cpmpanyName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '本周质检次数', colProp: ['qualityCount'] },
                ]
              )}" type="text" class="sale-datas">{{
                area.weekNum || 0
              }}</el-button></span
            >
            <span class="everyone  ever2"
              ><span class="sale-tit">本月新增质检次数</span
              ><el-button @click="() => { collect(
                'monthNum',
                '本月新增质检次数',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  
                  { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: rolesCompany },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属公司', colProp: ['cpmpanyName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '本月质检次数', colProp: ['qualityCount'] },
                ]
              )}" type="text" class="sale-datas">{{
                area.monthNum || 0
              }}</el-button></span
            >
          </li>
          <li style="display: flex;">
            <span class="everyone  ever2"
              ><span class="sale-tit">当日总咨询量</span
              ><el-button @click="() => { collect(
                'dayConsultationNum',
                '当日总咨询量',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  
                  { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: rolesCompany },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '所属平台', colProp: ['platformName'] },
                  { name: '咨询量', colProp: ['consultationNum'] },
                ]
              )}" type="text" class="sale-datas">{{
                area.dayConsultationNum || 0
              }}</el-button></span
            >
            <span class="everyone  ever2"
              ><span class="sale-tit">月累计咨询量</span
              ><el-button @click="() => { collect(
                'monthConsultationNum',
                '月累计咨询量',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  
                  { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: rolesCompany },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '所属平台', colProp: ['platformName'] },
                  { name: '咨询量', colProp: ['consultationNum'] },
                ]
              )}" type="text" class="sale-datas">{{
                area.monthConsultationNum || 0
              }}</el-button></span
            >
            <span class="everyone  ever2"
              >
              <el-tooltip
                class="item"
                effect="dark"
                content="当天登录服务店铺的客服数"
                placement="bottom"
                enterable
                >
              <span class="sale-tit">当日客服数量<img
                    src="@/assets/images/question.png"
                    class="tit-img"/></span
              >
              </el-tooltip>
              <el-button @click="() => { collect(
                'serviceLoginNum',
                '当日客服数量',
                [
                  { name: '客服名称', type: 'input', modelKey: 'serviceName' }
                ],
                [
                  { name: '客服名称', colProp: ['nickName'] },
                  { name: '所属部门', colProp: ['departName'] },
                  { name: '当前带店数', colProp: ['takeNum'] },
                  { name: '当前在线数', colProp: ['loginNUm'] }
                ]
              )}" type="text" class="sale-datas">{{
                area.serviceLoginNum || 0
              }}</el-button></span
            >
          </li>
          <li style="display: flex;">
            <span class="everyone  ever2"
              >
              <el-tooltip
                class="item"
                effect="dark"
                content="项目超出标准的数据预警"
                placement="bottom"
                enterable
                >
              <span class="sale-tit">项目异常数据预警<img
                    src="@/assets/images/question.png"
                    class="tit-img"/></span
              >
              </el-tooltip>
              <el-button @click="() => { collect(
                'projectAbnormalDataNum',
                '项目异常数据预警',
                [
                  { name: '客服名称', type: 'input', modelKey: 'adminName' },
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' }
                ],
                [
                  { name: '客服名称', colProp: ['adminName'] },
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属公司', colProp: ['companyName'] },
                  { name: '子账号', colProp: ['subAccountName'] },
                  { name: '首响', colProp: ['firstTime'] },
                  { name: '平响', colProp: ['avgTime'] },
                  { name: '问答比', colProp: ['qaRate'] },
                  { name: '满意度', colProp: ['satisfaction'] },
                  { name: '数据上传时间', colProp: ['excelDate'] },
                ]
              )}" type="text" class="sale-datas">{{
                area.projectAbnormalDataNum || 0
              }}</el-button></span
            >
            <span class="everyone  ever2"
              >
              <el-tooltip
                class="item"
                effect="dark"
                content="散号超出标准的数据预警"
                placement="bottom"
                enterable
                >
              <span class="sale-tit">散号异常数据预警<img
                    src="@/assets/images/question.png"
                    class="tit-img"/></span
              >
              </el-tooltip>
              <el-button @click="() => { collect(
                'unProjectAbnormalDataNum',
                '散号异常数据预警',
                [
                  { name: '客服名称', type: 'input', modelKey: 'adminName' },
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' }
                ],
                [
                  { name: '客服名称', colProp: ['adminName'] },
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属公司', colProp: ['companyName'] },
                  { name: '子账号', colProp: ['subAccountName'] },
                  { name: '首响', colProp: ['firstTime'] },
                  { name: '平响', colProp: ['avgTime'] },
                  { name: '问答比', colProp: ['qaRate'] },
                  { name: '满意度', colProp: ['satisfaction'] },
                  { name: '数据上传时间', colProp: ['excelDate'] },
                ]
              )}" type="text" class="sale-datas">{{
                area.unProjectAbnormalDataNum || 0
              }}</el-button></span
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="row11">
      <div class="oneCol">
        <!-- 项目 -->
        <div v-if="projectData && projectData.length" v-loading="loading2" element-loading-text="数据较多，拼命加载中..." class="col publicColW col11-1">
          <div style="display: flex;justify-content: space-between;">
            <!-- <el-radio-group v-model="radio1" class="xincheng">
              <el-radio-button label="辛橙项目"></el-radio-button>
            </el-radio-group> -->
            <el-tabs v-model="radio1" @tab-click="() => { getLineData() }"  class="xincheng">
              <el-tab-pane v-for="( item, index ) in projectData" :key="index" :label="item.name || '--'" :name="item.id + ''"></el-tab-pane>
            </el-tabs>
            <el-radio-group v-model="type" @change="changeRadio" class="days">
              <el-radio-button :label="0">昨日</el-radio-button>
              <el-radio-button :label="1">近7天</el-radio-button>
              <el-radio-button :label="2">近30天</el-radio-button>
              <el-radio-button :label="3">近90天</el-radio-button>
            </el-radio-group>
          </div>
          <double-line-charts
            v-if="flag && flag2"
            :legendName="'项目抽检比例'"
            :left="[
              {
                name: '参与质检店铺数',
                num: line1
                  ? lineData1.qualityedShopNum
                  : area.projectOrder && area.projectOrder.qualityedShopNum
              },
              {
                name: '抽检条数',
                num: line1
                  ? lineData1.checkNum
                  : area.projectOrder && area.projectOrder.checkNum
              },
              {
                name: '客服有问题的质检条数',
                num: line1
                  ? lineData1.problemNum
                  : area.projectOrder && area.projectOrder.problemNum
              }
            ]"
            :doubleMain="'doubluMain'"
            :doubleData="
              line1
                ? lineData1 : area.projectOrder"
            :fatherDate="[DateTransform(this.datePicker1[0]),DateTransform(this.datePicker1[1])]"
            :comId="params.companyId"
            :activeRadio="radio1"
            ref="chartsRef"
          ></double-line-charts>
        </div>
        <div v-else class="col publicColW col11-1" style="display: flex;justify-content: center;">
          <el-empty :image="nullImg" description="暂无项目数据"></el-empty>
        </div>
        <!-- 散号 -->
        <div
          v-loading="loading3"
          element-loading-text="数据较多，拼命加载中..."
          class="col publicColW col11-3"
        >
          <div
            class="cus"
            style="display: flex;justify-content: space-between;"
          >
            <div class="tit">散号相关质检数据统计</div>
            <el-radio-group v-model="type2" @change="changeRadio2" class="days">
              <el-radio-button :label="0">昨日</el-radio-button>
              <el-radio-button :label="1">近7天</el-radio-button>
              <el-radio-button :label="2">近30天</el-radio-button>
              <el-radio-button :label="3">近90天</el-radio-button>
            </el-radio-group>
          </div>
          <double-line-charts
            v-if="flag && flag3"
            :legendName="'散号抽检比例'"
            :left="[
              {
                name: '参与质检店铺数',
                num: line2
                  ? lineData2.qualityedShopNum
                  : area.unProjectOrder && area.unProjectOrder.qualityedShopNum
              },
              {
                name: '抽检条数',
                num: line2
                  ? lineData2.checkNum
                  : area.unProjectOrder && area.unProjectOrder.checkNum
              },
              {
                name: '客服有问题的质检条数',
                num: line2
                  ? lineData2.problemNum
                  : area.unProjectOrder && area.unProjectOrder.problemNum
              }
            ]"
            :doubleMain="'doubluMain2'"
            :doubleData="
              line2
                ? lineData2 : area.unProjectOrder"
            :fatherDate="[DateTransform(this.datePicker2[0]),DateTransform(this.datePicker2[1])]"
            :comId="params.companyId"
            
          ></double-line-charts>
        </div>
      </div>
      <div v-loading="loading6" element-loading-text="数据较多，拼命加载中..." class="col publicColW2 col11-2">
        <div class="publicTit tit spacebetween">
          质检条数数据统计
          <div>
            <el-radio-group v-model="label3" @change="NumChangeRadio">
              <el-radio-button :label="0">昨日</el-radio-button>
              <el-radio-button :label="1">今日</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <div style="display: flex;flex-direction: column;align-items: center;">
          <el-progress
            class="pro1"
            type="circle"
            :percentage="
             flag6? (area6.qualityNum&&area6.shouldQualityNum? ((area6.qualityNum / area6.shouldQualityNum) * 100 > 100 ? 100 : (area6.qualityNum / area6.shouldQualityNum) * 100): 0)
             : (area.qualityNum&&area.shouldQualityNum? ((area.qualityNum / area.shouldQualityNum) * 100 > 100 ? 100 : (area.qualityNum / area.shouldQualityNum) * 100): 0)
            "
            :width="288"
            :stroke-width="30"
            :format="format"
          ></el-progress>
          <el-progress
            class="pro2"
            type="circle"
            :percentage="
              flag6? (area6.qualityNum&&area6.readNum ?((area6.qualityNum / area6.readNum) * 100 > 100
                ? 100
                : (area6.qualityNum / area6.readNum) * 100): 0)
                :(area.qualityNum&&area.readNum ?((area.qualityNum / area.readNum) * 100 > 100
                ? 100
                : (area.qualityNum / area.readNum) * 100): 0)
            "
            :width="288"
            :stroke-width="30"
            :format="format"
            color="#52c41a"
          ></el-progress>
          <div class="service">
            <ul>
              <li>
                <span>应质检条数</span
                ><el-button @click="() => { collect(
                'shouldQualityNum',
                '应质检条数',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  
                  { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: rolesCompany },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属公司', colProp: ['cpmpanyName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '应质检条数', colProp: ['qualityCount'] },
                ]
              )}" type="text" class="text">{{
                  (flag6? area6.shouldQualityNum: area.shouldQualityNum) || 0
                }}</el-button>
              </li>
              <li>
                <span>实际质检条数</span
                ><el-button @click="() => { collect(
                'qualityNum',
                '实际质检条数',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  
                  { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: rolesCompany },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属公司', colProp: ['cpmpanyName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '实际质检条数', colProp: ['qualityCount'] },
                ]
              )}" type="text" class="text">{{
                  (flag6? area6.qualityNum: area.qualityNum) || 0
                }}</el-button>
              </li>
              <li>
                <span>未质检条数</span
                ><el-button @click="() => { collect(
                'unQualityNum',
                '未质检条数',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  
                  { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: rolesCompany },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属公司', colProp: ['cpmpanyName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '未质检条数', colProp: ['qualityCount'] },
                ]
              )}" type="text" class="text">{{
                  (flag6? area6.unQualityNum: area.unQualityNum) || 0
                }}</el-button>
              </li>
              <li>
                <span>质检已读条数</span
                ><el-button @click="() => { collect(
                'readNum',
                '质检已读条数',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  
                  { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: rolesCompany },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属公司', colProp: ['cpmpanyName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '质检已读条数', colProp: ['qualityCount'] },
                ]
              )}" type="text" class="text2">{{
                  (flag6? area6.readNum: area.readNum) || 0
                }}</el-button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

     <!-- 头部报表弹 -->
    <common-sum-dialog
      ref="commonSumDialog"
      :methodFuc="getQualityReportList"
      :commonSumVisible="commonSumVisible"
      @handleClose="sumVisibleClose"
    ></common-sum-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import pieCharts from "../children/pieCharts.vue"; //饼图
import doubleLineCharts from "../children/doubleLineCharts.vue"; //双折线图
import { getLatelyDay, DateTransform, Config} from "../../../utils/index.js";
import CommonSumDialog from "../../../components/common/commonSumDialog.vue";
import {
  getQualityStatisticNum,
  getQualityProjectNum,
  getQualityReportList,
  getQualityShopNum,
  getQualityOverViewNum
} from "../../../service/home";
import nullImg from "@/assets/images/no.png"

export default {
  name: "",
  components: { doubleLineCharts, pieCharts,CommonSumDialog },
  props: ["comId"],
  data() {
    return {
      // 汇总弹窗所需数据
      commonSumVisible: false,
      getQualityReportList, // 列表接口方法
      radio1: "",
      label: 0,
      label2: 0,
      label3: 0,//质检条数数据统计
      type: 3,
      type2: 3,
      DateTransform,
      rolesCompany: this.$sto.get(Config.constants.userInfo),
      flag: false,
      flag2: true,
      flag3: true,
      flag4: true,
      flag5: true,
      flag6: false,
      loading: false,
      loading2: false,
      loading3: false,
      loading4: false,
      loading5: false,
      loading6: false,
      params: {
        companyId: this.comId,
        startDate: "",
        endDate: ""
      },
      datePicker0:getLatelyDay(0),
      datePicker00:getLatelyDay(0),
      NumDatePicker:getLatelyDay(0),
      datePicker: getLatelyDay(0),  // 获取全部数据
      datePicker1: getLatelyDay(90), // 第一个双折线
      datePicker2: getLatelyDay(90), // 第2个双折线
      area: "",
      area4: "",
      area5: "",
      area6: "",
      lineData1: "", //项目图表
      line1: false,
      line2: false,
      line4: false,
      line5: false,
      lineData2: "", //散号图表
      nullImg,
      projectData: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    comId(val,oldVal){
      if(val != oldVal){
        this.params.companyId = val
        this.type = 3
        this.type2 = 3
        this.datePicker1= getLatelyDay(90), // 第一个双折线
        this.datePicker2= getLatelyDay(90) // 第2个双折线
      }
    }
  },
  //方法集合
  methods: {
    getProjectList(data) {
      this.projectData = data || []
      if (data && data.length) {
        this.radio1 = data[0].id + ''
      }
      this.getArea()
    },
    // 数据汇总弹窗-start
    sumVisibleClose() {
      this.commonSumVisible = false;
    },
    collect(type, name, screen, arr) {// cusType*********
      // 汇总弹窗-子组件参数 type：弹窗类型，name：弹窗标题，screen：筛选，arr：表格数据
      this.$refs.commonSumDialog.getAllData(
        type,
        name,
        screen,
        arr,
        // cusType
        this.params.companyId,
        '',
        '',
        '',
        this.NumDatePicker,
        "",
        "",
        "",
        "",
        "checkDate"
      );
      this.commonSumVisible = true;
    },
    // 数据汇总弹窗 -end

    // 图表1
    changeRadio(e) {
      let date;
      switch (e) {
        case 0:
          date = 0;
          break;
        case 1:
          date = 7;
          break;
        case 2:
          date = 30;
          break;
        case 3:
          date = 90;
          break;
      }
      this.datePicker1 = getLatelyDay(date);
      //   发送请求
      this.getLineData();
    },
    // 图表2
    changeRadio2(e) {
      let date;
      switch (e) {
        case 0:
          date = 0;
          break;
        case 1:
          date = 7;
          break;
        case 2:
          date = 30;
          break;
        case 3:
          date = 90;
          break;
      }
      this.datePicker2 = getLatelyDay(date);
      //   发送请求
      this.getLineData("line2");
    },
    format(percentage) {
      return percentage ? `${percentage.toFixed(2)}%` + "\n" : "0";
    },
    async getArea() {
      this.loading = true;
      this.flag = false;
      this.params.startDate = DateTransform(this.datePicker[0]);
      this.params.endDate = DateTransform(this.datePicker[1]);
      let params = { ...this.params };
      // params.cusType = "1";
      params.cusType = "217";
      const { data } = await getQualityStatisticNum(params);
      console.log(data, "质检区域");
      this.area = data;
      this.flag = true;
      this.loading = false;
      this.getLineData()
      // this.qualityProjectCharts()
      this.getLineData("line2")
      this.getNumQualityShop()
    },
    // 图表数据
    async getLineData(type) {
      if (type == "line2") {
        this.flag3 = false;
        this.loading3 = true;
        // this.params.cusType = "0";
        this.params.cusType = "218";
        this.params.startDate = DateTransform(this.datePicker2[0]);
        this.params.endDate = DateTransform(this.datePicker2[1]);
        this.params.projectId = 0
      } else {
        this.flag2 = false;
        this.loading2 = true;
        // this.params.cusType = "1";
        this.params.cusType = "217";
        this.params.projectId = this.radio1;
        console.log(this.datePicker1, '-----------------------------')
        this.params.startDate = DateTransform(this.datePicker1[0]);
        this.params.endDate = DateTransform(this.datePicker1[1]);
      }
      let params = { ...this.params };
      const { data } = await getQualityProjectNum(params);
      if (type == "line2") {
        this.line2 = true;
        this.lineData2 = data;
        this.flag3 = true;
        this.loading3 = false;
      } else {
        this.line1 = true;
        this.lineData1 = data;
        this.flag2 = true;
        this.loading2 = false;
      }
    },
    async getQualityShopNum(){
      this.loading4 = true
      this.flag4 = false
      this.params.startDate = DateTransform(this.datePicker0[0]);
      this.params.endDate = DateTransform(this.datePicker0[1]);
      let params = { ...this.params };
      const { data } = await getQualityShopNum(params)
      this.area4 = data
      this.flag4 = true
      this.line4 = true
      this.loading4 = false
    },
    async getQualityShopNum2(){
      this.loading5 = true
      this.flag5 = false
      this.params.startDate = DateTransform(this.datePicker00[0]);
      this.params.endDate = DateTransform(this.datePicker00[1]);
      let params = { ...this.params };
      const { data } = await getQualityShopNum(params)
      this.area5 = data
      this.flag5 = true
      this.line5 = true
      this.loading5 = false
    },
    changeRadio0(val) {
      this.label = val;
      if (val == 0) {
        this.datePicker0 = getLatelyDay(0);
      } else {
        this.datePicker0 = getLatelyDay(1);
      }
      this.getQualityShopNum();
    },
    changeRadio00(val) {
      this.label2 = val;
      if (val == 0) {
        this.datePicker00 = getLatelyDay(0);
      } else {
        this.datePicker00 = getLatelyDay(1);
      }
      this.getQualityShopNum2();
    },
    NumChangeRadio(val){ //质检条数数据统计
      this.label3 = val
      if (val == 0) {
        this.NumDatePicker = getLatelyDay(0);
      } else {
        this.NumDatePicker = getLatelyDay(1);
      }
      this.getNumQualityShop()
    },
    async getNumQualityShop(){
      this.loading6 = true
      this.flag6 = false
      let params = { 
        checkDate: DateTransform(this.NumDatePicker[0]),
        companyId: this.params.companyId
      };
      const { data } = await getQualityOverViewNum(params)
      this.area6 = data
      this.flag6 = true
      this.loading6 = false
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
};
</script>

<style lang="less" scoped>
/deep/ .el-progress-circle{
  width: 240px !important;
  height: 240px !important;
}
/deep/ .el-progress__text{
  display: flex;
  flex-direction: column;
  font-size: 34px !important;
  &::after{
    margin-top: 40px;
  }
}
.navBarWrap {
  position: fixed;
  top: 64px;
  left: 221px;
  right: 16px;
  z-index: 8;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(24, 144, 255, 50);
  .publicColTit {
    margin: 0 !important;
  }
}

/////////公用css START//////////
.col {
  background: #fff;
  border-radius: 8px;
  text-align: left;
}
.publicColTit {
  ///////////标题行**************
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 16px 32px;
  margin: 16px 0;
}
.publicColW {
  /////////// 大列宽度 **************
  width: 1229px !important;
  margin-right: 16px;
}
.publicColW2 {
  /////////// 小列宽度 **************
  box-sizing: border-box;
  width: 440px !important;
  padding: 32px;
}
.publicBox {
  ////////////三个小盒子***************
  box-sizing: border-box;
  width: 606px;
  padding: 32px;
  &:nth-child(2) {
    margin: 0 16px;
  }
}
.publicThreeBox {
  ////////////三个小盒子***************
  box-sizing: border-box;
  width: 440px;
  padding: 32px;
}
.pubColLine {
  display: inline-block;
  width: 1px;
  height: 18px;
  background: #999999;
  margin: -5px 8px;
}
.publicTit {
  //各平台服务中的店铺总数
  color: rgba(16, 16, 16, 100);
  font-size: 20px;
}
.publicTotal {
  //标题统计
  color: rgba(51, 51, 51, 100);
  font-size: 24px;
  // margin-left: 8px;
}
/deep/.pubTextBtn {
  span {
    text-decoration: underline;
    color: rgba(24, 144, 255, 100);
    font-size: 20px;
  }
}
/////////公用css END//////////

.active {
  color: #1890ff;
}
.iconfont {
  font-size: 32px;
  color: #1890ff;
}

.nav {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 99;
  transform: translate(0, -50%);
  width: 40px;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(24, 144, 255, 50);
}
.row10 {
  /deep/ .is-active {
    /deep/.el-radio-button__inner {
      // border: none;
      color: #1890ff !important;
      border-right: 1px solid #999 !important;
    }
  }
  .el-radio-button {
    height: 16px;
    &:nth-child(-n + 1) {
      border-right: 1px solid #999;
    }
  }
  /deep/.el-radio-button__inner {
    padding: 0 10px;
    border: none;
    background: transparent;
    color: #999999;
    box-shadow: none;
  }
  display: flex;
  .tit {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    color: rgba(16, 16, 16, 100);
    font-size: 20px;
  }
  .tit2 {
    margin-bottom: 85px;
  }
  .con {
    display: flex;
    align-items: center;
    /deep/.el-progress__text {
      white-space: pre;
      &::after {
        content: "当前订单目标进度";
        display: inline-block;
        color: rgba(51, 51, 51, 100);
        font-size: 14px;
      }
    }
    .year {
      margin-left: 40px;
      .total {
        color: rgba(51, 51, 51, 100);
        font-size: 14px;
      }
      .money {
        margin: 16px 0 32px;
        color: rgba(51, 51, 51, 100);
        font-size: 28px;
      }
      /deep/.text {
        // margin-top: 16px;
        text-align: left;
        span {
          font-size: 28px;
        }
      }
    }
  }
  .pie {
    position: relative;
    // margin-right: 40px;
    #main2,
    #main3,
    #main4 {
      width: 288px;
      height: 288px;
    }
    .totalShop {
      cursor: pointer;
      display: inline-block;
      text-decoration: underline;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 200px;
      text-align: center;
      position: absolute;
      top: 100px;
      left: 45px;
      font-size: 34px;
    }
    .tit-img {
      margin-left: 4px;
      vertical-align: middle;
    }
    .shopExplain {
      position: absolute;
      top: 155px;
      left: 100px;
      width: 89px;
      text-align: center;
      color: rgba(51, 51, 51, 100);
      font-size: 14px;
    }
  }
  .year {
    .total {
      color: rgba(51, 51, 51, 100);
      font-size: 14px;
    }
    .topPx {
      margin-top: 24px;
    }
    .money {
      margin: 16px 0 32px;
      color: rgba(51, 51, 51, 100);
      font-size: 28px;
    }
    .tit-img {
      margin-left: 4px;
      vertical-align: middle;
    }
    /deep/.text {
      text-align: left;
      span {
        font-size: 28px;
      }
    }
    /deep/ .shop1 {
      span {
        color: #1890ff;
        border-bottom: 2px solid #1890ff;
      }
    }
    /deep/ .shop2 {
      text-decoration: underline;
      text-decoration-color: #52c41a;
      span {
        color: #52c41a;
      }
    }
    /deep/ .shop3 {
      text-decoration: underline;
      text-decoration-color: #ff9e09;
      span {
        color: #ff9e09;
      }
    }
  }
  ul {
    li:nth-child(-n + 3) {
      margin-bottom: 24px;
    }
  }
  // ul {
  //   li{
  //     .everyone:nth-child(-n + 2) {
  //       margin-right: 16px;
  //     }
  //   }
  // }
  .everyone {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 33.3333%;
    // width: 112px;
    .sale-tit {
      color: rgba(51, 51, 51, 100);
      font-size: 14px;
      .tit-img {
        vertical-align: middle;
        margin-left: 4px;
      }
    }
    /deep/.sale-datas {
      text-decoration: underline;
      padding-bottom: 0;
      span {
        color: rgba(24, 144, 255, 100);
        font-size: 28px;
      }
    }
  }
  // .ever2 {
  //   width: 42%;
  // }
}
.row10 {
  .con {
    /deep/.el-progress__text {
      white-space: pre;
      &::after {
        content: "质检店铺率";
      }
    }
    .year {
      .tot2 {
        margin-top: 24px;
      }
      /deep/ .text {
        span {
          text-decoration: underline;
          color: rgba(51, 51, 51, 100);
          font-size: 28px;
        }
      }
      /deep/ .text2 {
        text-align: left;
        span {
          text-decoration: underline;
          color: rgba(24, 144, 255, 100);
          font-size: 28px;
        }
      }
    }
  }
}
.row11 {
  display: flex;
  margin-top: 16px;
  overflow: hidden;
  .oneCol {
    .col11-1 {
      width: 1227.5px;
      // height: 49.3%;
      height: 556px;
      box-sizing: border-box;
      padding: 32px;
      .xincheng {
        /deep/.el-tabs__nav {
          width: auto !important;
          height: 40px;
        }
        /deep/.el-tabs__header {
          margin-bottom: 16px;
        }
        /deep/.el-tabs__item {
          font-size: 20px;
          height: 34px;
          line-height: 34px;
          width: auto;
        }
        /deep/.el-tabs__item.is-active {
          border-bottom: solid 0.01042rem #1890ff;
          color: #1890ff;
        }
        /deep/ .el-tabs__nav-wrap {
          background: transparent;
        }
        /deep/ .el-tabs__nav {
          width: 200px;
          height: 40px;
        }
        /deep/.el-tabs__nav-wrap::after {
          background: transparent;
        }
        // .is-active {
        //   border-bottom: 2px solid #1890ff;
        // }
      }
    }
    .days {
      .el-radio-button:nth-child(-n + 3) {
        border-right: 0.00521rem solid #999;
      }
    }
    .col11-3 {
      width: 1227.5px;
      // height: 49.3%;
      height: 556px;
      margin-top: 16px;
      box-sizing: border-box;
      padding: 32px;
      .cus {
        margin-bottom: 32px;
      }
      .tit {
        color: rgba(16, 16, 16, 100);
        font-size: 20px;
      }
    }
  }

  .col11-2 {
    padding: 32px;
    .pro1 {
      margin: 32px 0;
      /deep/.el-progress__text {
        white-space: pre;
        &::after {
          content: "实际质检比例";
          display: inline-block;
          color: rgba(51, 51, 51, 100);
          font-size: 14px;
        }
      }
    }
    .pro2 {
      margin-bottom: 32px;
      /deep/.el-progress__text {
        white-space: pre;
        &::after {
          content: "质检已读比例";
          display: inline-block;
          color: rgba(51, 51, 51, 100);
          font-size: 14px;
        }
      }
    }
    .service {
      padding: 32px;
      background: #f2f9ff;
      width: 320px;
      border-radius: 8px;
      li:nth-child(-n + 3) {
        margin-bottom: 32px;
      }

      span {
        display: inline-block;
        width: 116px;
      }

      /deep/.text {
        text-decoration: underline;
        span {
          color: rgba(24, 144, 255, 100);
          font-size: 28px;
        }
      }
      /deep/.text2 {
        text-decoration: underline;
        text-decoration-color: rgba(82, 196, 26, 100);
        span {
          color: rgba(82, 196, 26, 100);
          font-size: 28px;
        }
      }
    }
  }
  .spacebetween{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-radio-button {
    height: 16px;
      &:nth-child(-n + 1) {
        border-right: 1px solid #999;
      }
    }
  }
}
</style>
