<!--销售 -->
<template>
  <div
    v-loading="loading"
    element-loading-text="数据较多，拼命加载中..."
    class=""
  >
    <!-- <div
          v-if="navBarFixed0"
          :class="['row2', navBarFixed0 ? 'navBarWrap' : '']"
        >
          <div class="col publicColTit">
            <img src="@/assets/images/saleIcon.png" />
            <span class="publicTotal">销售数据统计</span>
          </div>
        </div> -->
    <div class="row2" id="sale">
      <div class="col publicColTit">
        <!-- <i class="iconfont icon-xiaoshou"></i> -->
        <span class="publicTotal">营销数据统计</span>
      </div>
    </div>
    <div class="row3">
      <div class="col publicBox">
        <div class="tit">
          {{currentYear}}年公司订单目标进度
          <i
            @click="
              () => {
                yearDialogVisible = true;
              }
            "
            class="iconfont icon-shezhi"
          ></i>
        </div>
        <div class="con">
          <el-progress
            type="circle"
            :percentage="area.orderTotal&&area.orderTarget?
              (Number(((area.orderTotal / area.orderTarget) * 100).toFixed(2)) >=
              100
                ? 100
                : Number(((area.orderTotal / area.orderTarget) * 100).toFixed(2))):0"
            :stroke-width="15"
            :format="format"
          ></el-progress>
          <div
            class="year"
            style="display: flex;flex-direction: column;justify-content: center;"
          >
            <span class="total">年度目标总额</span>
            <span class="money">¥ {{ area.orderTarget }}</span>
            <span class="total">累计订单总额</span>
            <el-button
              @click="
                () => {
                  collect(
                    'orderTotal',
                    '累计订单总额',
                    [
                      {
                        name: '订单编号',
                        type: 'input',
                        modelKey: 'orderIdAndName'
                      },
                      {
                        name: '所属客户',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '订单编号', colProp: ['orderNo'], url: '/customer/total-order', paramsArr: [{urlKey: 'orderNo', paramsKey: 'orderNo'}, {urlKey: 'orderId', paramsKey: 'orderId'}] },
                      { name: '订单名称', colProp: ['orderName'] },
                      { name: '所属客户', colProp: ['customerName'] },
                      {
                        name: '服务时间',
                        colProp: ['startTime', 'endTime']
                      },
                      { name: '所属公司', colProp: ['companyName'] }
                    ]
                  );
                }
              "
              type="text"
              class="text"
              >￥{{ area.orderTotal || "0" }}</el-button
            >
          </div>
        </div>
      </div>
      <div class="col publicBox">
        <div class="tit2">
          店铺数服务状态统计
        </div>
        <div style="display: flex;align-items: center;">
          <!-- 图表 -->
          <!-- 圆环中心大标题 圆环中心小标题 类名 颜色[橙 绿 蓝] data -->
          <!-- <div v-if="area&&area.totalShopNum&&area.endServiceShopNum&&area.unServiceShopNum&&area.serviceShopNum"> -->
            <pie-charts
              v-if="flag"
              :centerTxt="area.totalShopNum || '0'"
              :subTxt="'累计店铺总数'"
              :pieName="'main'"
              :pieColor="[
                '#ff9e09',
                '#52c41a',
                '#1890ff'
              ]"
              :pieData="[
                { value: area.endServiceShopNum, name: '累计终止服务店铺数' },
                { value: area.unServiceShopNum, name: '待服务店铺数' },
                { value: area.serviceShopNum, name: '服务中的店铺数' }
              ]"
              :comId="comId"
            ></pie-charts>
          <!-- </div> -->
          <!-- <div v-else style="display: flex;justify-content: center;">
            <el-empty :image="nullImg2" description="暂无营销项目数据统计"></el-empty>
          </div> -->
          <div
            class="year"
            style="display: flex;flex-direction: column;justify-content: center;"
          >
            <span class="total">服务中的店铺数</span>
            <el-button
              @click="
                () => {
                  collect(
                    'serviceShopNum',
                    '今日服务中店铺数',
                    [
                      {
                        name: '所属客户',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                      { name: '所属客户', colProp: ['customerName'] },
                      { name: '服务时间', colProp: ['startDate', 'endDate'] }
                    ]
                  );
                }
              "
              type="text"
              class="text shop1"
              >{{ area.serviceShopNum || 0 }}</el-button
            >
            <el-tooltip
              class="item"
              effect="dark"
              content="未到服务时间的店铺总数"
              placement="bottom"
              enterable
              ><span class="total topPx"
                >待服务店铺数
                <img
                  src="../../../assets/images/question.png"
                  class="tit-img"/></span
            ></el-tooltip>
            <el-button
              @click="
                () => {
                  collect(
                    'unServiceShopNum',
                    '待服务店铺数',
                    [
                      {
                        name: '所属客户',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                      { name: '所属客户', colProp: ['customerName'] },
                      { name: '服务时间', colProp: ['startDate', 'endDate'] }
                    ]
                  );
                }
              "
              type="text"
              class="text shop2"
              >{{ area.unServiceShopNum || 0 }}</el-button
            >
            <el-tooltip
              class="item"
              effect="dark"
              content="当前所有服务终止的店铺总数"
              placement="bottom"
              enterable
              ><span class="total topPx"
                >累计终止服务店铺数<img
                  src="../../../assets/images/question.png"
                  class="tit-img"/></span
            ></el-tooltip>
            <el-button
              @click="
                () => {
                  collect(
                    'endServiceShopNum',
                    '累计终止服务店铺数',
                    [
                      {
                        name: '店铺名称',
                        type: 'input',
                        modelKey: 'shopName'
                      },
                      {
                        name: '所属客户',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                      { name: '所属客户', colProp: ['customerName'] },
                      { name: '所属平台', colProp: ['platformName'] },
                      { name: '关联订单数', colProp: ['orderNum'] },
                    ]
                  );
                }
              "
              type="text"
              class="text shop3"
              >{{ area.endServiceShopNum || 0 }}</el-button
            >
          </div>
        </div>
      </div>
      <div v-loading="markLoading" element-loading-text="数据较多，拼命加载中..." class="col publicThreeBox">
        <div class="tit">
          营销数据概览
          <div>
            <el-radio-group v-model="label" @change="changeRadio">
              <el-radio-button :label="0">昨日</el-radio-button>
              <el-radio-button :label="1">今日</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <ul style="width: 100%;">
          <li style="display: flex;">
            <span class="everyone"
              ><el-tooltip
                class="item"
                effect="dark"
                content="所有处于正在服务的订单的客户数量"
                placement="bottom"
                enterable
                ><span class="sale-tit"
                  >有效客户数<img
                    src="../../../assets/images/question.png"
                    class="tit-img"/></span></el-tooltip
              ><el-button @click="
                () => {
                  collect(
                    'servicingCustomerNum',
                    '有效客户数',
                    [
                      {
                        name: '客户名称',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '客户名称', colProp: ['customerName'], url: '/customer/customer-pool', paramsArr: [{urlKey: 'name', paramsKey: 'customerName'}, {urlKey: 'id', paramsKey: 'customerId'}] },
                      { name: '所属公司', colProp: ['companyName'] },
                      { name: '服务时间', colProp: ['startTime','endTime'] },
                    ]
                  );
                }
              " type="text" class="sale-datas">{{
               (markdataFlag? markData.servicingCustomerNum : area.servicingCustomerNum) || 0
              }}</el-button></span
            >
            <span class="everyone"
              ><span class="sale-tit">当前客户线索总数</span
              ><el-button @click="
                () => {
                  collect(
                    'customerTotalNum',
                    '当前客户线索总数',
                    [
                      {
                        name: '客户名称',
                        type: 'input',
                        modelKey: 'customerName'
                      },
                      { name: '客户状态', type: 'select', modelKey: 'customerState',selOpt: [{id: 0,name: '公海中'},{id: 1,name: '被销售捞取'},{id: 2,name: '已成单'},{id: 3,name: '已分配'}] }
                    ],
                    [
                      { name: '客户名称', colProp: ['customerName'], url: '/customer/customer-sea', paramsArr: [{urlKey: 'name', paramsKey: 'customerName'}, {urlKey: 'id', paramsKey: 'customerId'}] },
                      { name: '客户状态', colProp: ['stateName'] },
                    ]
                  );
                }
              " type="text" class="sale-datas">{{
                (markdataFlag? markData.customerTotalNum : area.customerTotalNum) || 0
              }}</el-button></span
            >
            <span class="everyone"
              ><span class="sale-tit"
                  >新增机会跟进数</span><el-button @click="
                () => {
                  collect(
                    'customerLaoNum',
                    '新增机会跟进数',
                    [
                      {
                        name: '销售机会名称',
                        type: 'input',
                        modelKey: 'chanceName'
                      },
                      {
                        name: '客户名称',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '销售机会名称', colProp: ['chanceName'], url: '/customer/sales-leads', paramsArr: [{urlKey: 'name', paramsKey: 'chanceName'}, {urlKey: 'id', paramsKey: 'chanceId'}, {urlKey: 'customerId', paramsKey: 'customerId'}] },
                      { name: '客户名称', colProp: ['customerName'] }
                    ]
                  );
                }
              " type="text" class="sale-datas">{{
                (markdataFlag? markData.customerLaoNum : area.customerLaoNum) || 0
              }}</el-button></span
            >
          </li>
          <li style="display: flex;">
            <span class="everyone"
              ><span class="sale-tit">销售机会总数</span
              ><el-button @click="
                () => {
                  collect(
                    'chanceTotalNum',
                    '销售机会总数',
                    [
                      {
                        name: '销售机会名称',
                        type: 'input',
                        modelKey: 'chanceName'
                      },
                      {
                        name: '客户名称',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '销售机会名称', colProp: ['chanceName'], url: '/customer/sales-leads', paramsArr: [{urlKey: 'name', paramsKey: 'chanceName'}, {urlKey: 'id', paramsKey: 'chanceId'}, {urlKey: 'customerId', paramsKey: 'customerId'}] },
                      { name: '客户名称', colProp: ['customerName'] },
                      { name: '销售阶段', colProp: ['stageName'] },
                      { name: '机会状态', colProp: ['stateName'] }
                    ]
                  );
                }
              " type="text" class="sale-datas">{{
                (markdataFlag? markData.chanceTotalNum : area.chanceTotalNum) || 0
              }}</el-button></span
            >
            <span class="everyone">
              <el-tooltip
                class="item"
                effect="dark"
                content="所有处于所有服务时间内的订单数量"
                placement="bottom"
                enterable
              >
                <span class="sale-tit"
                  >有效订单数<img
                    src="../../../assets/images/question.png"
                    class="tit-img"/></span></el-tooltip
              ><el-button @click="
                () => {
                  collect(
                    'validOrderNum',
                    '有效订单数',
                    [
                      {
                        name: '订单编号',
                        type: 'input',
                        modelKey: 'orderIdAndName'
                      },
                      {
                        name: '所属客户',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '订单编号', colProp: ['orderNo'], url: '/customer/total-order', paramsArr: [{urlKey: 'orderNo', paramsKey: 'orderNo'}, {urlKey: 'orderId', paramsKey: 'orderId'}] },
                      { name: '订单名称', colProp: ['orderName'] },
                      { name: '所属客户', colProp: ['customerName'] },
                      { name: '服务时间', colProp: ['startTime','endTime'] }
                    ]
                  );
                }
              " type="text" class="sale-datas">{{
                (markdataFlag? markData.validOrderNum : area.validOrderNum) || 0
              }}</el-button></span
            >
            <span class="everyone">
              <el-tooltip
                class="item"
                effect="dark"
                content="显示7天内服务到期的订单总数"
                placement="bottom"
                enterable
              >
                <span class="sale-tit"
                  >续签预警量<img
                    src="../../../assets/images/question.png"
                    class="tit-img"/></span></el-tooltip
              ><el-button @click="
                () => {
                  collect(
                    'warningOrderNum',
                    '续签预警量',
                    [
                      {
                        name: '订单编号',
                        type: 'input',
                        modelKey: 'orderIdAndName'
                      },
                      {
                        name: '所属客户',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '订单编号', colProp: ['orderNo'], url: '/customer/total-order', paramsArr: [{urlKey: 'orderNo', paramsKey: 'orderNo'}, {urlKey: 'orderId', paramsKey: 'orderId'}] },
                      { name: '订单名称', colProp: ['orderName'] },
                      { name: '所属客户', colProp: ['customerName'] },
                      { name: '服务时间', colProp: ['startTime','endTime'] }
                    ]
                  );
                }
              " type="text" class="sale-datas">{{
                (markdataFlag? markData.warningOrderNum : area.warningOrderNum) || 0
              }}</el-button></span
            >
          </li>
          <li style="display: flex;">
            <span class="everyone">
              <el-tooltip
                class="item"
                effect="dark"
                content="续签成功的订单总数"
                placement="bottom"
                enterable
              >
                <span class="sale-tit"
                  >续签订单数<img
                    src="../../../assets/images/question.png"
                    class="tit-img"/></span></el-tooltip
              ><el-button @click="
                () => {
                  collect(
                    'completeOrderNum',
                    '续签订单数',
                    [
                      {
                        name: '订单编号',
                        type: 'input',
                        modelKey: 'orderIdAndName'
                      },
                      {
                        name: '所属客户',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '订单编号', colProp: ['orderNo'], url: '/customer/total-order', paramsArr: [{urlKey: 'orderNo', paramsKey: 'orderNo'}, {urlKey: 'orderId', paramsKey: 'orderId'}] },
                      { name: '订单名称', colProp: ['orderName'] },
                      { name: '所属客户', colProp: ['customerName'] },
                      { name: '所属店铺', colProp: ['shopName'] },
                      { name: '服务时间', colProp: ['startTime','endTime'] },
                      { name: '订单状态', colProp: ['status'] }
                    ]
                  );
                }
              " type="text" class="sale-datas">{{
                (markdataFlag? markData.completeOrderNum : area.completeOrderNum) || 0
              }}</el-button></span
            >
            <span class="everyone">
              <el-tooltip
                class="item"
                effect="dark"
                content="没有续签成功的订单总数"
                placement="bottom"
                enterable
              >
                <span class="sale-tit"
                  >流失订单数<img
                    src="../../../assets/images/question.png"
                    class="tit-img"/></span></el-tooltip
              ><el-button @click="
                () => {
                  collect(
                    'failOrderNum',
                    '流失订单数',
                    [
                      {
                        name: '订单编号',
                        type: 'input',
                        modelKey: 'orderIdAndName'
                      },
                      {
                        name: '所属客户',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '订单编号', colProp: ['orderNo'], url: '/customer/total-order', paramsArr: [{urlKey: 'orderNo', paramsKey: 'orderNo'}, {urlKey: 'orderId', paramsKey: 'orderId'}] },
                      { name: '订单名称', colProp: ['orderName'] },
                      { name: '所属客户', colProp: ['customerName'] },
                      { name: '所属店铺', colProp: ['shopName'] },
                      { name: '服务时间', colProp: ['startTime','endTime'] },
                      { name: '订单状态', colProp: ['status'] }
                    ]
                  );
                }
              " type="text" class="sale-datas">{{
                (markdataFlag? markData.failOrderNum : area.failOrderNum) || 0
              }}</el-button></span
            >
            <span class="everyone">
              <el-tooltip
                class="item"
                effect="dark"
                content="非本月未收金额订单总数"
                placement="bottom"
                enterable
              >
                <span class="sale-tit"
                  >历史欠款订单<img
                    src="../../../assets/images/question.png"
                    class="tit-img"
                /></span> </el-tooltip
              ><el-button @click="
                () => {
                  collect(
                    'historyUnpaidOrderNum',
                    '历史欠款订单',
                    [
                      {
                        name: '订单编号/名称',
                        type: 'input',
                        modelKey: 'shopName'
                      },
                      {
                        name: '客户名称',
                        type: 'input',
                        modelKey: 'customerName'
                      },
                      {
                        name: '销售负责人',
                        type: 'input',
                        modelKey: 'marketName'
                      }
                    ],
                    [
                      { name: '订单编号', colProp: ['orderNo'], url: '/customer/total-order', paramsArr: [{urlKey: 'orderNo', paramsKey: 'orderNo'}, {urlKey: 'orderId', paramsKey: 'orderId'}] },
                      { name: '订单名称', colProp: ['orderName'] },
                      { name: '客户名称', colProp: ['customerName'] },
                      { name: '服务时间', colProp: ['startTime','endTime'] },
                      { name: '历史欠款金额', colProp: ['historyUnCollectionPrice'] },
                      { name: '销售负责人', colProp: ['marketName'] },
                      { name: '备注', colProp: ['note'] }
                    ]
                  );
                }
              " type="text" class="sale-datas">{{
                (markdataFlag? markData.historyUnpaidOrderNum : area.historyUnpaidOrderNum) || 0
              }}</el-button></span
            >
          </li>
        </ul>
      </div>
    </div>
    <div
      v-loading="loading2"
      element-loading-text="数据较多，拼命加载中..."
      class="row4"
    >
      <div class="col publicColW col4-1">
        <line-charts
          v-if="flag"
          :lineId="[
            'echartsLineA',
            'echartsLineB',
            'echartsLineC',
            'echartsLineD'
          ]"
          :homeData="line ? lineData : area.addOrder"
          :title="'新增数据统计'"
          :type="childType"
          @changeDate="changeDate"
        ></line-charts>
      </div>
      <div class="col publicColW2 col4-2">
        <div class="publicTit">各平台服务中的店铺总数</div>
        <ul class="con">
          <li class="con-item">
            <div>
              <img src="@/assets/images/TB.png" class="logo" /><span
                >淘宝店铺数</span
              >
            </div>
            <el-button
              @click="
                () => {
                  collect(
                    'taobaoShopNum',
                    '淘宝服务中店铺数',
                    [
                      {
                        name: '所属客户',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                      { name: '所属客户', colProp: ['customerName'] },
                      { name: '服务时间', colProp: ['startDate', 'endDate'] }
                    ]
                  );
                }
              "
              type="text"
              class="pubTextBtn"
              >{{
                (line
                  ? lineData.taobaoShopNum
                  : area.addOrder && area.addOrder.taobaoShopNum) || "0"
              }}</el-button
            >
          </li>
          <li class="con-item">
            <div>
              <img src="@/assets/images/TM.png" class="logo" /><span
                >天猫店铺数</span
              >
            </div>
            <el-button
              @click="
                () => {
                  collect(
                    'tianMaoShopNum',
                    '天猫服务中店铺数',
                    [
                      {
                        name: '所属客户',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                      { name: '所属客户', colProp: ['customerName'] },
                      { name: '服务时间', colProp: ['startDate', 'endDate'] }
                    ]
                  );
                }
              "
              type="text"
              class="pubTextBtn"
              >{{
                (line
                  ? lineData.tianMaoShopNum
                  : area.addOrder && area.addOrder.tianMaoShopNum) || "0"
              }}</el-button
            >
          </li>
          <li class="con-item">
            <div>
              <img src="@/assets/images/JD.png" class="logo" /><span
                >京东店铺数</span
              >
            </div>
            <el-button
              @click="
                () => {
                  collect(
                    'JDShopNum',
                    '京东服务中店铺数',
                    [
                      {
                        name: '所属客户',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                      { name: '所属客户', colProp: ['customerName'] },
                      {
                        name: '服务时间',
                        colProp: ['startDate', 'endDate']
                      }
                    ]
                  );
                }
              "
              type="text"
              class="pubTextBtn"
              >{{
                (line
                  ? lineData.JDShopNum
                  : area.addOrder && area.addOrder.JDShopNum) || "0"
              }}</el-button
            >
          </li>
          <li class="con-item">
            <div>
              <img src="@/assets/images/PXX.png" class="logo" /><span
                >拼多多店铺数</span
              >
            </div>
            <el-button
              @click="
                () => {
                  collect(
                    'pinduoduoShopNum',
                    '拼多多服务中店铺数',
                    [
                      {
                        name: '所属客户',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                      { name: '所属客户', colProp: ['customerName'] },
                      {
                        name: '服务时间',
                        colProp: ['startDate', 'endDate']
                      }
                    ]
                  );
                }
              "
              type="text"
              class="pubTextBtn"
              >{{
                (line
                  ? lineData.pinduoduoShopNum
                  : area.addOrder && area.addOrder.pinduoduoShopNum) || "0"
              }}</el-button
            >
          </li>
          <li class="con-item">
            <div>
              <img src="@/assets/images/DY.png" class="logo" /><span
                >抖音铺数</span
              >
            </div>
            <el-button
              @click="
                () => {
                  collect(
                    'tiktokShopNum',
                    '抖音服务中店铺数',
                    [
                      {
                        name: '所属客户',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                      { name: '所属客户', colProp: ['customerName'] },
                      {
                        name: '服务时间',
                        colProp: ['startDate', 'endDate']
                      }
                    ]
                  );
                }
              "
              type="text"
              class="pubTextBtn"
              >{{
                (line
                  ? lineData.tiktokShopNum
                  : area.addOrder && area.addOrder.tiktokShopNum) || "0"
              }}</el-button
            >
          </li>
          <li class="con-item">
            <div>
              <img src="@/assets/images/KS.png" class="logo" /><span
                >快手店铺数</span
              >
            </div>
            <el-button
              @click="
                () => {
                  collect(
                    'quickWorkerShopNum',
                    '快手服务中店铺数',
                    [
                      {
                        name: '所属客户',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                      { name: '所属客户', colProp: ['customerName'] },
                      {
                        name: '服务时间',
                        colProp: ['startDate', 'endDate']
                      }
                    ]
                  );
                }
              "
              type="text"
              class="pubTextBtn"
              >{{
                (line
                  ? lineData.quickWorkerShopNum
                  : area.addOrder && area.addOrder.quickWorkerShopNum) || "0"
              }}</el-button
            >
          </li>
          <li class="con-item">
            <div>
              <img src="@/assets/images/Other.png" class="logo" /><span
                >其他平台店铺数</span
              >
            </div>
            <el-button
              @click="
                () => {
                  collect(
                    'otherShopNum',
                    '其他平台服务中店铺数',
                    [
                      {
                        name: '所属客户',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                      { name: '所属客户', colProp: ['customerName'] },
                      {
                        name: '服务时间',
                        colProp: ['startDate', 'endDate']
                      }
                    ]
                  );
                }
              "
              type="text"
              class="pubTextBtn"
              >{{
                (line
                  ? lineData.otherWorkerShopNum
                  : area.addOrder && area.addOrder.otherWorkerShopNum) || 0
              }}</el-button
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- 辛橙项目显示判断 -->
    <div class="row5">
      <div v-if="projectData && projectData.length" class="col col5-1">
        <!-- <el-radio-group v-model="radio1" class="xincheng">
          <el-radio-button v-for="( item, index ) in projectData" :key="index" :value="item.id" :label="item.name"></el-radio-button>
        </el-radio-group> -->
        <el-tabs v-model="radio1" @tab-click="() => { saleProjectCharts() }"  class="xincheng">
          <el-tab-pane v-for="( item, index ) in projectData" :key="index" :label="item.name || '--'" :name="item.id + ''"></el-tab-pane>
        </el-tabs>
        <bar-charts
          v-if="flag"
          :homeData="projectCharts"
          :options1="options1"
          :barMain="['pro1', 'pro2']"
          :rightData="projectCharts"
          :activeRadio="radio1"
          :comId="comId"
          ref="barChartsRef"
        ></bar-charts>
      </div>
      <div v-else class="col col5-1">
        <el-empty :image="nullImg" description="暂无营销项目数据统计"></el-empty>
      </div>
    </div>

    <el-dialog
      title="年度目标总额"
      :visible.sync="yearDialogVisible"
      width="30%"
      :before-close="yearHandleClose"
      append-to-body
    >
      <div class="demo-input-suffix">
        年度目标总额：
        <el-input
          class="common-screen-input"
          placeholder="请输入"
          v-model="score"
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="common-screen-btn"
          @click="
            () => {
              yearDialogVisible = false;
            }
          "
          >取 消</el-button
        >
        <el-button
          type="primary"
          class="common-screen-btn"
          @click="
            () => {
              sendYearAim();
            }"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 头部报表弹 -->
    <common-sum-dialog
      ref="commonSumDialog"
      :methodFuc="getMarketReportList"
      :commonSumVisible="commonSumVisible"
      @handleClose="sumVisibleClose"
    ></common-sum-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import lineCharts from "../children/lineCharts.vue"; //折线图
import pieCharts from "../children/pieCharts.vue"; //饼图
import barCharts from "../children/barCharts.vue"; //柱图
import {
  saleArea,
  getMarketAddNum,
  getMarketReportList,
  updateOrderTarget,
  saleProjectCharts,
  getMarketOverviewNum
} from "../../../service/home.js";
import { DateTransform, getLatelyDay } from "../../../utils/index.js";
import CommonSumDialog from "../../../components/common/commonSumDialog.vue";
import nullImg from "@/assets/images/line.png"
import nullImg2 from "@/assets/images/pie.png"

export default {
  name: "",
  components: { pieCharts, barCharts, lineCharts, CommonSumDialog },
  props: ["comId"],
  data() {
    return {
      // 汇总弹窗所需数据
      commonSumVisible: false,
      getMarketReportList, // 列表接口方法
      flag: false, //父组件异步请求数据回来在传到子组件
      loading: false,
      loading2: false,
      radio1: "",
      label: 0,
      currentYear: '2021', // 当前年份
      options1: [
        {
          value: "1",
          label: "项目服务每月总咨询量"
        },
        {
          value: "2",
          label: "项目服务每月产能金额"
        }
      ],
      params: {
        companyId: this.comId,
        startDate: "2021-06-22",
        endDate: "2021-06-22"
      },
      markdatePicker: getLatelyDay(0),//营销数据概览
      datePicker: getLatelyDay(0),
      datePicker2: getLatelyDay(90),
      area: "",
      lineData: "", //折线图数据
      line: false,
      childType: "3",
      /////////////////////报表
      headerType: "",
      dialogTit: "",
      dialogVisible: false,
      paramsTit: [],
      childColName: [],
      yearDialogVisible: false,
      score: "", //设置的值
      nullImg,
      nullImg2,
      projectData: [],
      projectCharts: {},
      markdataFlag: false,
      markLoading: false,
      markData: {}
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    comId(val,oldVal){
      if(val != oldVal){
        this.params.companyId = val
        this.childType = "3",
        this.datePicker2 = getLatelyDay(90)
      }
    }
  },
  //方法集合
  methods: {
    getProjectList(data) {
      this.projectData = data || []
      if (data && data.length) {
        this.radio1 = data[0].id + ''
        this.saleProjectCharts()
      }
      
    },
    // changeProject () { // 切换项目
    //   console.log(this.radio1)
    // },
    async saleProjectCharts() { //项目图表数据
      this.loading = true
      let { data } = await saleProjectCharts({companyId: this.comId, cusType: '217', projectId: this.radio1})
      this.loading = false
      this.projectCharts = data || {}
      setTimeout(() => {
        this.$refs.barChartsRef.drawBar()
      }, 10)
      
    },
    // 数据汇总弹窗
    sumVisibleClose() {
      this.commonSumVisible = false;
    },
    collect(type, name, screen, arr) {
      // 汇总弹窗-子组件参数 type：弹窗类型，name：弹窗标题，screen：筛选，arr：表格数据
      this.$refs.commonSumDialog.getAllData(
        type,
        name,
        screen,
        arr,
        this.params.companyId,
        "",
        "",
        "",
        this.markdatePicker,
        "",
        "",
        "",
        "",
        "checkDate"
      );
      this.commonSumVisible = true;
    },
    // 数据汇总弹窗end
    async getArea() {
      this.loading = true;
      this.flag = false;
      this.params.startDate = DateTransform(this.datePicker[0]);
      this.params.endDate = DateTransform(this.datePicker[1]);
      let params = { ...this.params };
      // params.cusType = "1";
      params.cusType = "217";
      const { data } = await saleArea(params);
      this.area = data;
      this.flag = true;
      this.loading = false;
      this.getLineCharts() //单独获取图表
      this.getMarketOverviewData()
    },
    // 获取图表数据
    async getLineCharts() {
      this.loading2 = true;
      this.flag = false;
      this.line = true;
      this.params.startDate = DateTransform(this.datePicker2[0]);
      this.params.endDate = DateTransform(this.datePicker2[1]);
      let params = { ...this.params };
      const { data } = await getMarketAddNum(params);
      this.lineData = data;
      this.flag = true;
      this.loading2 = false;
    },
    format(percentage) {
      return percentage ? `${percentage}%` + "\n" : "0";
    },
    // 图表日期
    changeDate(days, num) {
      this.datePicker2 = getLatelyDay(days);
      this.childType = num;
      this.getLineCharts();
    },
    ///////////////////////////////////////////////
    headerParam(type, name, screen, arr) {
      this.dialogTit = name;
      this.headerType = type;
      this.paramsTit = screen;
      this.childColName = arr;
      this.dialogVisible = true;
      // this.comId = this.params.companyId
      this.$refs.homeChild.getHeaderList(type);
    },
    handleClose() {
      this.dialogVisible = false;
    },
    changeRadio(val) {
      this.label = val;
      if (val == 0) {
        this.markdatePicker = getLatelyDay(0);
      } else {
        this.markdatePicker = getLatelyDay(1);
      }
      this.getMarketOverviewData();
    },
    yearHandleClose() {
      this.score = "";
      this.yearDialogVisible = false;
    },
    async sendYearAim() {
      const res = await updateOrderTarget({score: this.score,companyId: this.params.companyId});
      console.log(res);
      if(res.code != 200){
        return this.$message.error("设置失败")
      }
      this.$message.success("设置成功")
      this.yearDialogVisible = false
      this.getArea()
    },
    async getMarketOverviewData() {
      this.markLoading = true
      this.markdataFlag = false;
      let params = {
        companyId: this.params.companyId,
        checkDate: DateTransform(this.markdatePicker[0])
      }
      const { data } = await getMarketOverviewNum(params)
      this.markData = data.data
      this.markdataFlag = true
      this.markLoading = false
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.currentYear = (new Date()).getFullYear()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // this.getArea()
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-progress-circle{
  width: 240px !important;
  height: 240px !important;
}
/deep/ .el-progress__text{
  display: flex;
  flex-direction: column;
  font-size: 34px !important;
  &::after{
    margin-top: 20px;
  }
}
.navBarWrap {
  position: fixed;

  top: 64px;
  left: 221px;
  right: 16px;
  z-index: 8;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(24, 144, 255, 50);
  .publicColTit {
    margin: 0 !important;
  }
}

/////////公用css START//////////
.col {
  background: #fff;
  border-radius: 8px;
  text-align: left;
}
.publicColTit {
  ///////////标题行**************
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 16px 32px;
  margin: 16px 0;
}
.publicColW {
  /////////// 大列宽度 **************
  width: 1229px !important;
  margin-right: 16px;
}
.publicColW2 {
  /////////// 小列宽度 **************
  box-sizing: border-box;
  width: 440px !important;
  padding: 32px;
}
.publicBox {
  ////////////三个小盒子***************
  box-sizing: border-box;
  width: 606px;
  padding: 32px;
  &:nth-child(2) {
    margin: 0 16px;
  }
}
.publicThreeBox {
  ////////////三个小盒子***************
  box-sizing: border-box;
  width: 440px;
  padding: 32px;
}
.pubColLine {
  display: inline-block;
  width: 1px;
  height: 18px;
  background: #999999;
  margin: -5px 8px;
}
.publicTit {
  //各平台服务中的店铺总数
  color: rgba(16, 16, 16, 100);
  font-size: 20px;
}
.publicTotal {
  //标题统计
  color: rgba(51, 51, 51, 100);
  font-size: 24px;
  // margin-left: 8px;
}
/deep/.pubTextBtn {
  span {
    text-decoration: underline;
    color: rgba(24, 144, 255, 100);
    font-size: 20px;
  }
}
/////////公用css END//////////

.active {
  color: #1890ff;
}
.iconfont {
  font-size: 32px;
  color: #1890ff;
}

.nav {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 99;
  transform: translate(0, -50%);
  width: 40px;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(24, 144, 255, 50);
}

.row3 {
  display: flex;
  height: 378px;
  .tit {
    display: flex;
    justify-content: space-between;
    margin-bottom: 33px;
    color: rgba(16, 16, 16, 100);
    font-size: 20px;
    .icon-shezhi {
      vertical-align: middle;
      font-size: 20px;
      color: rgba(153, 153, 153, 1);
      &:hover {
        color: #1890ff;
        cursor: pointer;
      }
    }
  }
  .tit2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    color: rgba(16, 16, 16, 100);
    font-size: 20px;
    .icon-shezhi {
      vertical-align: middle;
      font-size: 20px;
      color: rgba(153, 153, 153, 1);
      &:hover {
        color: #1890ff;
        cursor: pointer;
      }
    }
  }
  .con {
    display: flex;
    // margin-top: 56px;
    /deep/.el-progress__text {
      white-space: pre;
      &::after {
        content: "当前订单目标进度";
        display: inline-block;
        color: rgba(51, 51, 51, 100);
        font-size: 14px;
      }
    }
    .year {
      width: 200px !important;
      margin-left: 40px;
      .total {
        color: rgba(51, 51, 51, 100);
        font-size: 14px;
      }
      .money {
        margin: 16px 0 32px;
        color: rgba(51, 51, 51, 100);
        font-size: 28px;
      }
      /deep/.text {
        margin-top: 16px;
        text-decoration: underline;
        text-align: left;
        padding-bottom: 0;
        span {
          font-size: 28px;
        }
      }
    }
  }
  .pie {
    position: relative;
    margin-right: 40px;
    #main {
      width: 288px;
      height: 288px;
    }
    .totalShop {
      cursor: pointer;
      display: inline-block;
      text-decoration: underline;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 200px;
      text-align: center;
      position: absolute;
      top: 100px;
      left: 45px;
      font-size: 34px;
    }
  }
  .year {
    // margin-left: 40px;
    .total {
      color: rgba(51, 51, 51, 100);
      font-size: 14px;
    }
    .topPx {
      margin-top: 24px;
    }
    .money {
      margin: 16px 0 32px;
      color: rgba(51, 51, 51, 100);
      font-size: 28px;
    }
    .tit-img {
      margin-left: 4px;
      vertical-align: middle;
    }
    /deep/.text {
      text-align: left;
      span {
        font-size: 28px;
      }
    }
    /deep/ .shop1 {
      padding-bottom: 0;
      span {
        text-decoration: underline;
        text-decoration-color: #1890ff;
        color: #1890ff;
        // border-bottom: 2px solid #1890ff;
      }
    }
    /deep/ .shop2 {
      padding-bottom: 0;
      span {
        text-decoration: underline;
        text-decoration-color: #52c41a;
        color: #52c41a;
        // border-bottom: 2px solid #52c41a;
      }
    }
    /deep/ .shop3 {
      padding-bottom: 0;
      span {
        text-decoration: underline;
        text-decoration-color: #ff9e09;
        color: #ff9e09;
        // border-bottom: 2px solid #ff9e09;
      }
    }
  }
  ul {
    li:nth-child(-n + 2) {
      margin-bottom: 24px;
    }
  }
  .everyone {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 33.33%;
    .sale-tit {
      color: rgba(51, 51, 51, 100);
      font-size: 14px;
      .tit-img {
        vertical-align: middle;
        margin-left: 4px;
      }
    }
    /deep/.sale-datas {
      text-decoration: underline;
      padding-bottom: 0;
      span {
        color: rgba(24, 144, 255, 100);
        font-size: 28px;
      }
    }
  }

  /deep/ .is-active {
    .el-radio-button__inner {
      border: none;
      color: #1890ff !important;
    }
  }
  .el-radio-button {
    height: 16px;
    &:nth-child(-n + 1) {
      border-right: 1px solid #999;
    }
  }
  /deep/.el-radio-button__inner {
    padding: 0 10px;
    border: none;
    background: transparent;
    color: #999999;
    box-shadow: none;
  }
}
.row4 {
  display: flex;
  margin: 16px 0;
  .col4-1 {
    box-sizing: border-box;
    padding: 32px;
  }
  .col4-2 {
    .con {
      margin-top: 32px;
      li {
        margin-top: 16px;
        box-sizing: border-box;
        height: 48px;
        background: #f2f9ff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 16px;
        .logo {
          margin-right: 16px;
        }
      }
    }
  }
}
.row5 {
  .col5-1 {
    padding: 18px 24px;
    .xincheng {
      /deep/.el-tabs__header {
        margin-bottom: 16px;
      }
      /deep/.el-tabs__item {
        font-size: 20px;
        height: 34px;
        line-height: 34px;
        width: auto;
      }
      /deep/.el-tabs__item.is-active {
        border-bottom: solid 0.01042rem #1890ff;
        color: #1890ff;
      }
    }
    .el-radio-button {
      border: none;
    }
    /deep/ .el-radio-button__inner {
      font-size: 20px;
    }
    /deep/ .el-tabs__nav-wrap {
      background: transparent;
    }
    /deep/ .el-tabs__nav {
      width: 200px;
      height: 40px;
    }
    /deep/.el-tabs__nav-wrap::after {
      background: transparent;
    }
  }
}
</style>
