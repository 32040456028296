<!-- 流程 -->
<template>
  <div class="">
    <!-- <div
          v-if="navBarFixed5"
          :class="['row17', navBarFixed5 ? 'navBarWrap' : '']"
        >
          <div class="col publicColTit">
            <img src="@/assets/images/processIcon.png" />
            <span class="publicTotal">岗位工作流程</span>
          </div>
        </div> -->
    <div class="row17">
      <div class="col publicColTit">
        <!-- <i class="iconfont icon-liucheng"></i> -->
        <span class="publicTotal">岗位工作流程</span>
      </div>
    </div>
    <div class="row18">
      <div class="col publicColW col18-1">
        <div class="roles">
          <!-- <span @click="() => {changRole(1)}" :class="[activeClass1? 'roleItem1':'roleItem2']">{{role.roles[0]}}</span> -->
          <span
            @click="
              () => {
                changRole(1);
              }
            "
            v-if="
              role.roles[0] == '超级管理员' ||
                role.roles[0] == '运营主管' ||
                role.roles[0] == '总部运营' ||
                role.roles[0] == '公司管理员'
            "
            :class="[activeClass1 ? 'roleItem1' : 'roleItem2']"
            >运营</span
          >
          <span
            @click="
              () => {
                changRole(2);
              }
            "
            v-if="menus.includes('营销')"
            :class="[activeClass2 ? 'roleItem1' : 'roleItem2']"
            >销售</span
          >
          <span
            @click="
              () => {
                changRole(3);
              }
            "
            v-if="menus.includes('客服')"
            :class="[activeClass3 ? 'roleItem1' : 'roleItem2']"
            >客服</span
          >
          <span
            @click="
              () => {
                changRole(4);
              }
            "
            v-if="menus.includes('质检')"
            :class="[activeClass4 ? 'roleItem1' : 'roleItem2']"
            >质检</span
          >
          <span
            @click="
              () => {
                changRole(5);
              }
            "
            v-if="menus.includes('财务')"
            :class="[activeClass5 ? 'roleItem1' : 'roleItem2']"
            >财务</span
          >
          <span
            @click="
              () => {
                changRole(6);
              }
            "
            v-if="menus.includes('组织')"
            :class="[activeClass6 ? 'roleItem1' : 'roleItem2']"
            >组织</span
          >
        </div>
        <div
          v-if="
            (role.roles[0] == '超级管理员' ||
              role.roles[0] == '运营主管' ||
              role.roles[0] == '总部运营' ||
              role.roles[0] == '公司管理员') &&
              activeNum == 1
          "
          class="con"
        >
          <span class="process1">分配代班客服</span>
          <span class="process1 process1-2">创建/分配子账号</span>
          <span class="process1 process1-2">安排客服班次</span>
          <span class="process1 process1-2">服务数据维护</span>
          <span class="process1 process1-2">维护店铺</span>
          <span class="process1 process1-2">管理账号权限</span>
          <span class="process1 process1-2">发布公告</span>
        </div>
        <div v-if="role.roles[0].includes('销售') && activeNum == 1" class="con">
          <span class="process1">创建客户</span>
          <span class="process1 process1-2">捞取机会</span>
          <span class="process1 process1-2">创建订单</span>
          <span class="process1 process1-2">关联店铺</span>
          <span class="process1 process1-2">维护订单</span>
          <span class="process1 process1-2">改价收款</span>
          <span class="process1 process1-2">续签订单</span>
        </div>
        <div v-if="role.roles[0] == '客服' && activeNum == 1" class="con">
          <span class="process1">修改个人信息</span>
          <span class="process1 process1-2">确认个人班次</span>
          <span class="process1 process1-2">查看带店信息</span>
          <span class="process1 process1-2">下载店铺服务标准</span>
          <span class="process1 process1-2">质检结果申诉</span>
        </div>
        <div v-if="role.roles[0] == '质检' && activeNum == 1" class="con">
          <span class="process1">设置质检分数</span>
          <span class="process1 process1-2">设置质检分类</span>
          <span class="process1 process1-2">质检客服服务</span>
          <span class="process1 process1-2">上传服务数据</span>
          <span class="process1 process1-2">处理客服申诉</span>
        </div>
        <div v-if="role.roles[0] == '财务' && activeNum == 1" class="con">
          <span class="process1">管理进账</span>
          <span class="process1 process1-2">催收未收账款</span>
          <span class="process1 process1-2">记录出账</span>
          <span class="process1 process1-2">确认退款</span>
          <span class="process1 process1-2">校验流水</span>
        </div>
        <div
          v-if="role.roles[0].includes('人事') && activeNum == 1"
          class="con"
        >
          <span class="process1">设置部门</span>
          <span class="process1 process1-2">设置角色</span>
          <span class="process1 process1-2">管理员工入/离职</span>
          <span class="process1 process1-2">维护员工账号</span>
          <span class="process1 process1-2">统计考勤</span>
        </div>
        <div v-if="activeNum == 2" class="con">
          <span class="process1">创建客户</span>
          <span class="process1 process1-2">捞取机会</span>
          <span class="process1 process1-2">创建订单</span>
          <span class="process1 process1-2">关联店铺</span>
          <span class="process1 process1-2">维护订单</span>
          <span class="process1 process1-2">改价收款</span>
          <span class="process1 process1-2">续签订单</span>
        </div>
        <div v-if="activeNum == 3" class="con">
          <span class="process1">修改个人信息</span>
          <span class="process1 process1-2">确认个人班次</span>
          <span class="process1 process1-2">查看带店信息</span>
          <span class="process1 process1-2">下载店铺服务标准</span>
          <span class="process1 process1-2">质检结果申诉</span>
        </div>
        <div v-if="activeNum == 4" class="con">
          <span class="process1">设置质检分数</span>
          <span class="process1 process1-2">设置质检分类</span>
          <span class="process1 process1-2">质检客服服务</span>
          <span class="process1 process1-2">上传服务数据</span>
          <span class="process1 process1-2">处理客服申诉</span>
        </div>
        <div v-if="activeNum == 5" class="con">
          <span class="process1">管理进账</span>
          <span class="process1 process1-2">催收未收账款</span>
          <span class="process1 process1-2">记录出账</span>
          <span class="process1 process1-2">确认退款</span>
          <span class="process1 process1-2">校验流水</span>
        </div>
        <div v-if="activeNum == 6" class="con">
          <span class="process1">设置部门</span>
          <span class="process1 process1-2">设置角色</span>
          <span class="process1 process1-2">管理员工入/离职</span>
          <span class="process1 process1-2">维护员工账号</span>
          <span class="process1 process1-2">统计考勤</span>
        </div>
      </div>
      <div class="col publicColW2 col18-2">
        <div class="right-down">
          <div class="ad" style="display: flex;">
            <span class="down-tit">桌面端软件下载链接</span>
            <a
              :href="
                `${deskDownload}${tableData &&
                  tableData[0] &&
                  tableData[0].version}.exe`
              "
              class="down-a"
            >
              https://sy-my.oss-cn-zhangjiakou.aliyuncs.com/sy/file/%E4%B8%89%E5%9C%86%E8%BD%AF%E4%BB%B6%E5%8C%85/dist_electron/%E4%B8%89%E5%9C%86%E6%99%BA%E8%83%BD%E5%AE%A2%E6%9C%8D%20Setup
            </a>
          </div>
          <!-- <div class="ad" style="display: flex;">
            <span class="down-tit">官网地址</span>
            <a href="https://www.fwwb.vip/" target="_blank" class="down-a"
              >https://www.fwwb.vip/</a
            >
          </div> -->
          <div
            class="ad"
            v-if="getUserInfo.company_id == 23"
            style="display: flex;"
          >
            <span class="down-tit">数据投屏</span>
            <a :href="screenUrl" target="_blank" class="down-a">{{
              screenUrl
            }}</a>
          </div>
          <div class="ad" style="display: flex;">
            <span class="down-tit">版本号</span>
            <span>1.3.5</span>
          </div>
          <!-- <div style="display: flex;justify-content: space-between;">
            <div class="erweima">
              <img src="@/assets/images/erweima.png" alt="" class="img" />
              <span class="bigtit">三圆公众号</span>
            </div>
            <div class="erweima">
              <img src="@/assets/images/minipro.jpg" alt="" class="img" />
              <span class="bigtit">三圆小程序</span>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { Config, deskDownload } from "../../../utils/index.js";
import { mapState } from "vuex";

export default {
  name: "",
  components: {},
  props: ["menus", "role", "tableData"],
  data() {
    return {
      Config,
      deskDownload,
      // tableData: [],
      screenUrl:
        window.location.protocol +
        "//" +
        window.location.host +
        "/dataScreen/index.html",
      activeNum: 1,
      activeClass1: true,
      // activeClass2: false,
      activeClass2: this.role.roles[0].includes("销售"),
      // activeClass3: false,
      activeClass3: this.role.roles[0].includes("客服"),
      // activeClass4: false,
      activeClass4: this.role.roles[0].includes("质检"),
      // activeClass5: false,
      activeClass5: this.role.roles[0].includes("财务"),
      // activeClass6: false
      activeClass6: this.role.roles[0].includes("人事")
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState(["getUserInfo"])
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    changRole(num) {
      this.activeNum = num;
      num > 1 ? (this.activeClass1 = false) : (this.activeClass1 = true),
        (this.activeClass2 = false),
        (this.activeClass3 = false),
        (this.activeClass4 = false),
        (this.activeClass5 = false),
        (this.activeClass6 = false);
      this["activeClass" + num] = true;
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
};
</script>

<style lang="less" scoped>
.navBarWrap {
  position: fixed;
  top: 64px;
  left: 221px;
  right: 16px;
  z-index: 8;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(24, 144, 255, 50);
  .publicColTit {
    margin: 0 !important;
  }
}

/////////公用css START//////////
.col {
  background: #fff;
  border-radius: 8px;
  text-align: left;
}
.publicColTit {
  ///////////标题行**************
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 16px 32px;
  margin: 16px 0;
}
.publicColW {
  /////////// 大列宽度 **************
  width: 1229px !important;
  margin-right: 16px;
}
.publicColW2 {
  /////////// 小列宽度 **************
  box-sizing: border-box;
  width: 440px !important;
  padding: 32px;
}
.publicBox {
  ////////////三个小盒子***************
  box-sizing: border-box;
  width: 606px;
  padding: 32px;
  &:nth-child(2) {
    margin: 0 16px;
  }
}
.publicThreeBox {
  ////////////三个小盒子***************
  box-sizing: border-box;
  width: 440px;
  padding: 32px;
}
.pubColLine {
  display: inline-block;
  width: 1px;
  height: 18px;
  background: #999999;
  margin: -5px 8px;
}
.publicTit {
  //各平台服务中的店铺总数
  color: rgba(16, 16, 16, 100);
  font-size: 20px;
}
.publicTotal {
  //标题统计
  color: rgba(51, 51, 51, 100);
  font-size: 24px;
  // margin-left: 8px;
}
/deep/.pubTextBtn {
  span {
    text-decoration: underline;
    color: rgba(24, 144, 255, 100);
    font-size: 20px;
  }
}
/////////公用css END//////////

.active {
  color: #1890ff;
}
.iconfont {
  font-size: 32px;
  color: #1890ff;
}

.nav {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 99;
  transform: translate(0, -50%);
  width: 40px;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(24, 144, 255, 50);
}
.row18 {
  display: flex;
  // margin-bottom: 90px;
  // margin-bottom: 150px;
  .col18-1 {
    box-sizing: border-box;
    padding: 32px;
    // padding-top: 93px;
    .roles {
      .roleItem1 {
        display: inline-block;
        cursor: pointer;
        width: 180px;
        height: 40px;
        margin: 0 7px;
        color: #fff;
        font-size: 14px;
        text-align: center;
        line-height: 40px;
        border-radius: 2px;
        background-color: rgba(24, 144, 255, 100);
        &:nth-child(1) {
          margin-left: 0;
        }
        &:last-child(1) {
          margin-right: 0;
        }
      }
      .roleItem2 {
        display: inline-block;
        cursor: pointer;
        box-sizing: border-box;
        margin: 0 7px;
        width: 180px;
        height: 40px;
        color: rgba(24, 144, 255, 100);
        font-size: 14px;
        text-align: center;
        line-height: 40px;
        border: 1px solid rgba(24, 144, 255, 100);
        border-radius: 2px;
        background-color: rgba(242, 249, 255, 100);
        &:nth-child(1) {
          margin-left: 0;
        }
        &:last-child(1) {
          margin-right: 0;
        }
      }
    }
    .con {
      box-sizing: border-box;
      margin-top: 32px;
      width: 1164px;
      height: 104px;
      border-radius: 8px;
      background-color: rgba(242, 249, 255, 100);
      padding: 32px;
      .process1 {
        display: inline-block;
        width: 150px;
        height: 40px;
        line-height: 40px;
        color: rgba(255, 255, 255, 100);
        font-size: 14px;
        text-align: center;
        background-image: url("~@/assets/images/rolesbg1.png");
        background-size: 100%;
      }
      .process1-2 {
        background-image: url("~@/assets/images/rolesbg1-2.png");
        background-size: 100%;
      }
    }
  }
  .col18-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 438px !important;
    .right-down {
      width: 100%;
      background-color: #fff;
      box-sizing: border-box;
      text-align: start;
      border-radius: 8px;
      .ad:nth-child(-n + 3) {
        margin-bottom: 24px;
      }
      .down-tit {
        display: inline-block;

        color: #333333;
        font-size: 12px;
        text-align: start;
        margin-right: 65px;
        width: 126px;
      }
      .down-a {
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: #1890ff;
        // width: 127px;
        width: 188px;
      }
      .img {
        width: 120px;
        // height: 72px;
        margin-bottom: 8px;
      }
      .erweima {
        display: flex;
        flex-direction: column;
        align-items: center;
        // margin-right: 65px;
        // &:nth-child(1) {
        //   margin-right: 126px;
        // }
      }
      .bigtit {
        display: inline-block;
        margin-bottom: 24px;
        color: #333333;
        font-size: 12px;
        text-align: start;
      }
    }
  }
}
</style>
