<!-- 首页页面 -->
<template>
  <div
    v-loading="loading"
    element-loading-text="数据较多，拼命加载中..."
    class="home-container"
    ref="navWrapper"
  >
    <div class="row1">
      <div style="overflow: hidden;">
        <div class="col publicColW col1-1">
          <div class="col-left">
            <img
              v-if="getUserInfo && getUserInfo.icon"
              :src="getUserInfo.icon"
              class="col1Img"
            />
            <i v-else class="el-icon-user iconPeople"></i>
          </div>
          <div class="col-middle">
            <span class="midW" style="display: flex;flex-direction: column;">
              <span class="name"
                ><span class="ro-name">{{ getUserInfo.nick_name }}</span
                ><span class="roles"
                  ><img src="@/assets/images/roles.svg" class="role-img" />
                  {{ getUserInfo.roles && getUserInfo.roles[0] }}</span
                >
                <span class="wel" style="color: #1890ff;cursor: pointer;margin: 0 0 0 16px;line-height: 42px" @click="() => { $router.push(`/personal-center?userId=${getUserInfo.user_id}`) }">查看完整信息</span>
              </span>
              
              <span class="companyName">
                <span v-show="getUserInfo.company_name" class="companyName"
                  >{{ getUserInfo.company_name }}
                </span>
                <span v-show="getUserInfo.depart_name" class="companyName"
                  >&nbsp;|&nbsp;{{ getUserInfo.depart_name }}</span
                >
              </span>

              <!-- <span class="wel">员工账号：{{getUserInfo.account || '--'}} 邮箱：{{getUserInfo.mail || '--'}}</span> -->
              <span class="wel">欢迎使用帮客智能客服管理系统！</span>
              
            </span>
          </div>
          <div class="col-right">
            <!-- <div v-if="!$vuex.state.onlyCompany" class="common-input-container">
            <el-select
              class="common-screen-input"
              @change="commonFun"
              v-model="params.companyId"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in rolesCompany0"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div v-else class="common-input-container">
            &nbsp;
          </div> -->
            <span class="time">北京时间 {{ time }}</span>
            <span class="dateweek">{{ date }}&nbsp;&nbsp;&nbsp;{{ week }}</span>
            <span class="ip">IP: {{ getUserInfo.ip }}</span>
            
          </div>
        </div>
        <div class="col publicColW col1-3">
          <div style="display: flex;justify-content: space-between;">
            <span class="work">工单事项</span>
            <div>
              <el-radio-group v-if="workFlag"  v-model="label" @change="changeRadio">
                <el-radio-button :label="0">个人</el-radio-button>
                <el-radio-button  :label="1">全公司</el-radio-button>
              </el-radio-group>
            </div>
          </div>
          <div style="display: flex;justify-content: space-around;">
            <div style="display: flex;justify-content: space-around;">
              <span class="works"
                >{{label == 0? '待办任务':'未完成任务'}}
                <el-button
                  @click="
                    () => {
                      collect(
                        '2',
                        label == 0? '待办任务':'未完成任务',
                        [
                          {
                            name: '任务工单名称',
                            type: 'input',
                            modelKey: 'taskName'
                          },
                          {
                            name: '类型名称',
                            type: 'workSelect',
                            modelKey: 'workOrderId'
                          },
                          {
                            name: '优先级',
                            type: 'select',
                            modelKey: 'priority',
                            selOpt: [
                              { id: 1, name: '低' },
                              { id: 2, name: '中' },
                              { id: 3, name: '高' },
                              { id: 4, name: '紧急' }
                            ]
                          },
                          {
                            name: '工单进展状态',
                            type: 'select',
                            modelKey: 'status',
                            selOpt: [
                              { id: 1, name: '进行中' },
                              { id: 2, name: '已完成' },
                              { id: 3, name: '终止' }
                            ]
                          }
                        ],
                        [
                          { name: '任务工单名称', colProp: ['taskName'] },
                          { name: '类型名称', colProp: ['workTypeName'] },
                          { name: '工单进展状态', colProp: ['status'] },
                          { name: '优先级', colProp: ['priority'] },
                          { name: '描述', colProp: ['remark'] },
                          { name: '创建人', colProp: ['createName'] },
                          { name: '创建时间', colProp: ['createTime'] }
                        ],
                        1
                      );
                    }
                  "
                  type="text"
                  class="workNum work-red"
                  >{{ workObj.taskPendingList || 0 }}</el-button
                >
              </span>
              <span class="works line"
                >{{label == 0? '创建的任务':'全部任务'}}
                <el-button
                  @click="
                    () => {
                      collect(
                        label  == 0? '3':'1',
                        label == 0? '创建的任务':'全部任务',
                        [
                          {
                            name: '任务工单名称',
                            type: 'input',
                            modelKey: 'taskName'
                          },
                          {
                            name: '类型名称',
                            type: 'workSelect',
                            modelKey: 'workOrderId'
                          },
                          {
                            name: '优先级',
                            type: 'select',
                            modelKey: 'priority',
                            selOpt: [
                              { id: 1, name: '低' },
                              { id: 2, name: '中' },
                              { id: 3, name: '高' },
                              { id: 4, name: '紧急' }
                            ]
                          },
                          {
                            name: '工单进展状态',
                            type: 'select',
                            modelKey: 'status',
                            selOpt: [
                              { id: 1, name: '进行中' },
                              { id: 2, name: '已完成' },
                              { id: 3, name: '终止' }
                            ]
                          }
                        ],
                        [
                          { name: '任务工单名称', colProp: ['taskName'] },
                          { name: '类型名称', colProp: ['workTypeName'] },
                          { name: '工单进展状态', colProp: ['status'] },
                          { name: '优先级', colProp: ['priority'] },
                          { name: '描述', colProp: ['remark'] },
                          { name: '创建人', colProp: ['createName'] },
                          { name: '创建时间', colProp: ['createTime'] }
                        ],
                        1
                      );
                    }
                  "
                  type="text"
                  class="workNum work-blue"
                  >{{ workObj.taskAllList || 0 }}</el-button
                >
              </span>
            </div>
            <div style="display: flex;justify-content: space-around;">
              <span class="works"
                >{{label == 0? '待办审批':'未完成审批'}}
                <el-button
                  @click="
                    () => {
                      collect(
                        '2',
                        label == 0? '待办审批':'未完成审批',
                        [
                          {
                            name: '任务工单名称',
                            type: 'input',
                            modelKey: 'taskName'
                          },
                          {
                            name: '类型名称',
                            type: 'workSelect',
                            modelKey: 'workOrderId'
                          },
                          {
                            name: '优先级',
                            type: 'select',
                            modelKey: 'priority',
                            selOpt: [
                              { id: 1, name: '低' },
                              { id: 2, name: '中' },
                              { id: 3, name: '高' },
                              { id: 4, name: '紧急' }
                            ]
                          },
                          {
                            name: '工单进展状态',
                            type: 'select',
                            modelKey: 'status',
                            selOpt: [
                              { id: 1, name: '进行中' },
                              { id: 2, name: '已完成' },
                              { id: 3, name: '终止' }
                            ]
                          }
                        ],
                        [
                          { name: '审批工单名称', colProp: ['taskName'] },
                          { name: '类型名称', colProp: ['workTypeName'] },
                          { name: '工单进展状态', colProp: ['status'] },
                          { name: '优先级', colProp: ['priority'] },
                          { name: '描述', colProp: ['remark'] },
                          { name: '创建人', colProp: ['createName'] },
                          { name: '创建时间', colProp: ['createTime'] }
                        ],
                        2
                      );
                    }
                  "
                  type="text"
                  class="workNum work-red"
                  >{{ workObj.approvalPendingList || 0 }}</el-button
                >
              </span>
              <span class="works"
                >{{label==0? '创建的审批':'全部审批'}}
                <el-button
                  @click="
                    () => {
                      collect(
                        label==0 ? '3':'1',
                        label==0? '创建的审批':'全部审批',
                        [
                          {
                            name: '任务工单名称',
                            type: 'input',
                            modelKey: 'taskName'
                          },
                          {
                            name: '类型名称',
                            type: 'workSelect',
                            modelKey: 'workOrderId'
                          },
                          {
                            name: '优先级',
                            type: 'select',
                            modelKey: 'priority',
                            selOpt: [
                              { id: 1, name: '低' },
                              { id: 2, name: '中' },
                              { id: 3, name: '高' },
                              { id: 4, name: '紧急' }
                            ]
                          },
                          {
                            name: '工单进展状态',
                            type: 'select',
                            modelKey: 'status',
                            selOpt: [
                              { id: 1, name: '进行中' },
                              { id: 2, name: '已完成' },
                              { id: 3, name: '终止' }
                            ]
                          }
                        ],
                        [
                          { name: '审批工单名称', colProp: ['taskName'] },
                          { name: '类型名称', colProp: ['workTypeName'] },
                          { name: '工单进展状态', colProp: ['status'] },
                          { name: '优先级', colProp: ['priority'] },
                          { name: '描述', colProp: ['remark'] },
                          { name: '创建人', colProp: ['createName'] },
                          { name: '创建时间', colProp: ['createTime'] }
                        ],
                        2
                      );
                    }
                  "
                  type="text"
                  class="workNum work-blue"
                  >{{ workObj.approvalAllList || 0 }}</el-button
                >
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col publicColW2 col1-2">
        <div
          class="head"
          style="display: flex;align-items: center;justify-content: space-between;"
        >
          <span class="tit">通知中心</span>
          <el-button
            @click="
              () => {
                seeall();
              }
            "
            type="text"
            >查看全部</el-button
          >
        </div>
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="最新动态" name="first">
            <div class="right-news">
              <div v-for="item in logList" :key="item.id" class="news-id">
                <span class="news-createtime">{{ item.createTime }}</span>
                <span class="news-username">{{ item.userName }}</span>
                <span class="news-des">{{ item.description }}</span>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="公告" name="second">
            <ul>
              <li
                v-for="(item, i) in notice"
                :key="i"
                class="notice-id"
                style="display: flex;justify-content: space-between;"
              >
                <span class="adtit">{{ item.adviceTitle }}</span>
                <span class="report-time">{{ item.publishTime }}</span>
              </li>
            </ul>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <ul>
      <!-- 销售数据统计区域 -->
      <li>
        <div v-if="menus && menus.includes('营销')" id="sale">
          <sale ref="sale" :comId="params.companyId"></sale>
        </div>
      </li>
      <li>
        <div v-if="menus && menus.includes('客服')" id="cus">
          <custome ref="cus" :comId="params.companyId"></custome>
        </div>
      </li>
      <li>
        <div v-if="menus && menus.includes('质检')" id="service">
          <service ref="service" :comId="params.companyId"></service>
        </div>
      </li>
      <li>
        <div v-if="menus && menus.includes('财务')" id="money">
          <money ref="money" :comId="params.companyId"></money>
        </div>
      </li>
      <li>
        <div v-if="menus && menus.includes('组织')" id="human">
          <human ref="human" :comId="params.companyId"></human>
        </div>
      </li>
      <!-- 岗位工作流程区域 -->
      <li id="process">
        <process
          v-if="rolesCompany"
          ref="processPage"
          :tableData="tableData"
          :menus="menus"
          :role="rolesCompany"
        ></process>
      </li>
    </ul>

    <!-- 右侧导航栏 -->
    <div class="nav">
      <ul>
        <li
          @click="jump(i)"
          v-for="(item, i) in menuList"
          :key="i"
          :class="activeMenu == i ? 'active' : ''"
          style="display: flex;flex-direction: column;justify-content: center;"
        >
          <i v-if="menus.indexOf(item.tit) > -1" :class="item.icon"></i
          ><span v-if="menus.indexOf(item.tit) > -1">{{ item.tit }}</span>
        </li>
        <li
          @click="jump2()"
          :class="activeMenu == menus.length ? 'active' : ''"
          style="display: flex;flex-direction: column;justify-content: center;"
        >
          <i class="iconfont icon-a-2"></i><span>流程</span>
        </li>
      </ul>
    </div>

    <see-dialog
      ref="seeDialog"
      :title="noticeName"
      :comId="params.companyId"
      :visible="seeVisible"
      @closeSeeDialog="closeSeeDialog"
    ></see-dialog>

    <!-- 头部报表弹 -->
    <common-sum-dialog
      ref="commonSumDialog"
      :methodFuc="workList"
      :commonSumVisible="commonSumVisible"
      @handleClose="sumVisibleClose"
    ></common-sum-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import {
  selectList,
  systemNotice,
  projectList,
  getRepairOrder,
  workList
} from "../../service/home";
import { versionList, userData } from "../../service/common.js";
import { mapState } from "vuex";
import { getLatelyDay, Config, getWeek } from "../../utils/index.js";
import CommonSumDialog from "../../components/common/commonSumDialog.vue";
import seeDialog from "./children/seeDialog.vue";
///////////////////////////
import sale from "./component/sale.vue";
import custome from "./component/custome.vue";
import service from "./component/service.vue";
import money from "./component/money.vue";
import human from "./component/human.vue";
import process from "./component/process.vue";

export default {
  name: "",
  components: {
    // "el-image-viewer": () =>
    //   import("element-ui/packages/image/src/image-viewer"),
    CommonSumDialog,
    seeDialog,
    sale,
    custome,
    service,
    money,
    human,
    process
  },
  data() {
    return {
      Config,
      homeData: {},
      params: {
        type: 3, // 筛选类型 7天/30天/90天/ 昨天 ...
        datePicker: getLatelyDay(7) // 时间范围选择-默认7天
      },
      loading: false,
      info: {}, //个人信息
      time: "",
      date: "",
      week: "",
      logList: [], //最新动态
      notice: [], //公告
      params2: {
        pageNum: 1,
        pageSize: 10
      },
      label: 0,
      tableData: [],
      timer: "",
      /////////////////////报表
      headerType: "",
      dialogTit: "",
      dialogVisible: false,
      rolesCompany: this.$sto.get(Config.constants.userInfo),
      rolesCompany0: {}, // 全部公司下拉
      // rolesCompany0: this.$sto.get(Config.constants.userInfo).roles_company.filter((item) => { return item.id != 0 }), // 全部公司下拉
      paramsTit: [],
      childColName: [],
      activeName: "first",
      ////////////////////////////////////////////////////
      // radio1: "辛橙项目",
      menuList: [],
      menus: [],
      activeMenu: -1, //菜单索引
      navBarFixed0: false,
      navBarFixed1: false,
      navBarFixed2: false,
      navBarFixed3: false,
      navBarFixed4: false, //组织
      navBarFixed5: false,
      total0: null,
      total1: null,
      total2: null,
      total3: null,
      total4: null,
      // flag: false, //父组件异步请求数据回来在传到子组件
      noticeName: "最新动态",
      seeVisible: false,
      seeColName: [],
      timer1: null,
      area1: "",
      objJson: [],
      projectData: [],
      workObj: {}, //工单字段
      // 汇总弹窗所需数据
      commonSumVisible: false,
      workList, // 列表接口方法
      workFlag: false
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState(["getUserInfo"])
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 数据汇总弹窗
    sumVisibleClose() {
      this.commonSumVisible = false;
    },
    collect(type, name, screen, arr, workTypeId) {
      // 汇总弹窗-子组件参数 type：弹窗类型，name：弹窗标题，screen：筛选，arr：表格数据
      this.$refs.commonSumDialog.getAllData(
        type,
        name,
        screen,
        arr,
        this.label == 1 ? this.params.companyId : "",
        this.label == 0 ? this.rolesCompany.user_id : "",
        "",
        "",
        "",
        "",
        "work",
        workTypeId,
        this.params.companyId
      );
      this.commonSumVisible = true;
    },
    // 数据汇总弹窗
    async projectList() {
      // 获取项目列表
      let { data } = await projectList({
        infoPageNum: 3,
        companyId: this.params.companyId
      });
      this.projectData = data || [];
      if (this.$refs.sale) {
        this.$refs.sale.getProjectList(data); //销售子方法
      }
    },
    // 数据汇总弹窗end
    jump(i) {
      this.activeMenu = i;
      document.querySelector("#" + this.menuList[i].id).scrollIntoView(true);
    },
    jump2() {
      this.activeMenu = this.menus.length;
      document.querySelector("#process").scrollIntoView(true);
    },
    handleClick(tab) {
      if (tab.name == "second") {
        this.noticeName = "公告";
        this.getSystemNotice();
      } else {
        this.noticeName = "最新动态";
        this.getLogList();
      }
    },
    async firstInHome() {
      // 第一次进入homme页面
      let { data } = await userData();
      if (data) {
        this.$vuex.commit("getUserInfo", data);
        if(data.roles.join(',').indexOf('管理员') != -1){
          this.workFlag = true
        }
      }

      this.rolesCompany = data || [];
      this.rolesCompany0 =
        data.roles_company.filter((item) => {
          return item.id != 0;
        }) || [];
      this.menus = [];
      this.menuList = [];
      this.objJson = [];

      let arr = ["营销", "客服", "质检", "财务", "组织"];
      arr.forEach((item) => {
        data.menus.forEach((element) => {
          if (item == element.name) {
            this.menus.push(element.name);
            if (element.name == "营销") {
              this.menuList.push({
                icon: "iconfont icon-a-1",
                tit: "营销",
                id: "sale"
              });
              this.objJson.push({ 营销: "sale" });
            } else if (element.name == "客服") {
              this.menuList.push({
                icon: "iconfont icon-icon-kefu",
                tit: "客服",
                id: "cus"
              });
              this.objJson.push({ 客服: "cus" });
            } else if (element.name == "质检") {
              this.menuList.push({
                icon: "iconfont icon-icon-zhijian",
                tit: "质检",
                id: "service"
              });
              this.objJson.push({ 质检: "service" });
            } else if (element.name == "财务") {
              this.menuList.push({
                icon: "iconfont icon-untitled",
                tit: "财务",
                id: "money"
              });
              this.objJson.push({ 财务: "money" });
            } else if (element.name == "组织") {
              this.menuList.push({
                icon: "iconfont icon-icon-renshi",
                tit: "组织",
                id: "human"
              });
              this.objJson.push({ 组织: "human" });
            }
          }
        });
      });

      if (this.rolesCompany) {
        this.params.companyId = this.rolesCompany.company_id;
      }
      this.getLogList();

      setTimeout(() => {
        this.getFirstArea();
      }, 100);
      this.getRepairOrderList(this.label); //工单字段
    },
    // 最新动态
    async getLogList() {
      this.loading = true;
      let params = { ...this.params };
      const { data: res } = await selectList({ companyId: params.companyId });
      this.logList = res;
      // this.infoDate = res;
      this.loading = false;
    },
    // 公告
    async getSystemNotice() {
      this.loading = true;
      let params = { ...this.params };
      const { data: res } = await systemNotice({
        companyId: params.companyId,
        pageSize: 6
      });
      this.notice = res;
      // this.infoDate = res;
      this.loading = false;
    },
    async versionList() {
      let params = { ...this.params2 };
      let resData = (await versionList(params)).data;

      if (resData.records && resData.records.length) {
        this.tableData = resData.records;
      }
    },
    ///////////////////////////////////////////////
    handleClose() {
      this.dialogVisible = false;
    },
    // 页面 滚动 事件
    handleScroll() {
      // 当前元素与底部的距离 = 可视区窗口高度 + 文档滚动高度 - 当前元素与页面顶部距离 - 当前元素高度

      let winHeight = document.documentElement.clientHeight; //可视区窗口高度
      // let winHeight = document.body.scrollHeight
      const that = this;
      let scrollY = document.getElementsByClassName("home-container")[0]
        .scrollTop; //文档滚动高度
      // console.log(winHeight,scrollY,'可视区窗口高度 + 文档滚动高度');
      let distance5 = document.getElementById("process");
      // let total5 = distance5.offsetTop - 260;
      let total5 = distance5.offsetTop;

      let objJson = this.objJson;

      this.menus.forEach((element, index) => {
        objJson.forEach((item, i) => {
          if (element == Object.keys(objJson[i])[0]) {
            //第一个存在objJson调用
            let distance = document.getElementById(objJson[i][element]);
            // this["total" + index] = distance.offsetTop - 260;
            this["total" + index] = distance.offsetTop; //当前元素与页面顶部距离
            // console.log(this["total" + index],'当前元素与页面顶部距离');
          }
        });
      });

      this.menus.forEach((element, index) => {
        if (this["total" + index]) {
          if (scrollY < this["total0"]) {
            //第一次进页面，没有滚动，不激活
            this.activeMenu = -1;
            this.navBarFixed0 = true;
          } else if (this.menus.length == 1) {
            if (
              scrollY >= this["total0"] &&
              scrollY < total5 - this["total0"]
            ) {
              this.activeMenu = 0;
            } else {
              this.activeMenu = this.menus.length;
            }
          } else if (
            winHeight + scrollY - this["total" + index] > 120 &&
            scrollY <= this["total" + (this.menus.length - 1)]
          ) {
            objJson.forEach((item, i) => {
              if (Object.keys(objJson[i])[0] != this.menus[0]) {
                if (!this["navBarFixed" + index]) {
                  if (objJson[i][element]) {
                    this.activeMenu = i;
                    if (objJson[i][element] != "service") {
                      that.$refs[objJson[i][element]].getArea(); //子方法
                    }
                    this["navBarFixed" + index] = true;

                    // if (objJson[i][element] == "sale") {
                    //   that.$refs[objJson[i][element]].getProjectList(this.projectData); //子方法
                    // }
                    if (objJson[i][element] == "service") {
                      that.$refs[objJson[i][element]].getProjectList(
                        this.projectData
                      ); //子方法
                    }
                  }
                }
              } else if (Object.keys(objJson[i])[0] == this.menus[0]) {
                this.activeMenu = index;
              }
            });
          } else if (scrollY > this["total" + (this.menus.length - 1)]) {
            this.activeMenu = this.menus.length;
          }
        }
      });
    },
    seeall() {
      if (this.activeName == "first") {
        this.$refs.seeDialog.getAllActions();
      } else {
        this.$refs.seeDialog.getAllNotice();
      }
      this.seeVisible = true;
    },
    closeSeeDialog() {
      this.seeVisible = false;
    },
    getFirstArea() {
      this.objJson.forEach((element, index) => {
        if (this.menus[0] == Object.keys(this.objJson[index])[0]) {
          // Object.keys(this.objJson[index])[0] == '营销'   this.objJson[index]=="{ 营销: "sale" }"
          //第一个存在objJson调用
          if (
            this.$refs[this.objJson[index][Object.keys(this.objJson[index])[0]]]
          ) {
            if (!this.navBarFixed0) {
              this.$nextTick(() => {
                this.$refs[
                  this.objJson[index][Object.keys(this.objJson[index])[0]]
                ].getArea(); //子方法
                this.projectList();
                // if (this.objJson[index][Object.keys(this.objJson[index])[0]] == "sale") {
                //   this.$refs[this.objJson[index][Object.keys(this.objJson[index])[0]]].getProjectList(this.projectData); //子方法
                // }
              });
            }
            this.navBarFixed0 = true;
          }
        }
      });
    },
    // 公司下拉改变
    commonFun() {
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.getLogList();
      this.navBarFixed0 = false; //如果是下拉公司改变，重置为false  F会走方法
      this.navBarFixed1 = false; //如果是下拉公司改变，重置为false  F会走方法
      this.navBarFixed2 = false; //如果是下拉公司改变，重置为false  F会走方法
      this.navBarFixed3 = false; //如果是下拉公司改变，重置为false  F会走方法
      this.navBarFixed4 = false; //如果是下拉公司改变，重置为false  F会走方法
      this.getFirstArea();
      this.label = 0;
      this.getRepairOrderList(0);
    },
    changeRadio(val) {
      this.getRepairOrderList(val);
      // this.getArea();
    },
    async getRepairOrderList(num) {
      let rolesCompany = this.rolesCompany;
      if (rolesCompany) {
        if (num == 0) {
          const { data } = await getRepairOrder({
            adminId: rolesCompany.user_id
          });
          this.workObj = data;
        }
        if (num == 1) {
          if (rolesCompany.roles.join(",").indexOf("管理员") != -1) {
            const { data } = await getRepairOrder({
              companyId: this.params.companyId
            });
            this.workObj = data;
          }
        }
      }
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    const storageInfo = this.$sto.get(Config.constants.userInfo);
    this.info = storageInfo;
    // 获取时间
    let myDate = new Date().getTime() + 500;
    this.timer = setInterval(() => {
      // DateTransform
      myDate += 1000;
      let newDate = new Date(myDate);
      this.time =
        (newDate.getHours() < 10
          ? "0" + newDate.getHours()
          : newDate.getHours()) +
        ":" +
        (newDate.getMinutes() < 10
          ? "0" + newDate.getMinutes()
          : newDate.getMinutes()) +
        ":" +
        (newDate.getSeconds() < 10
          ? "0" + newDate.getSeconds()
          : newDate.getSeconds());
    }, 1000);

    let nowDate = new Date();
    let year = nowDate.getFullYear();
    let mon = nowDate.getMonth() + 1;
    mon = mon < 10 ? "0" + mon : mon;
    let day = nowDate.getDate();
    day = day < 10 ? "0" + day : day;

    this.date = year + "年" + mon + "月" + day + "日";
    this.week = getWeek(nowDate);

    this.$vuex.dispatch("getMenuInfo");

    this.projectList();
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.firstInHome();
    this.versionList();

    window.addEventListener("scroll", this.handleScroll, true);
  }
};
</script>

<style lang="less" scoped>
.home-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: overlay;

  .navBarWrap {
    position: fixed;
    top: 64px;
    left: 221px;
    right: 16px;
    z-index: 8;
    border-radius: 8px;
    box-shadow: 0px 2px 6px 0px rgba(24, 144, 255, 50);
    .publicColTit {
      margin: 0 !important;
    }
  }

  /////////公用css START//////////
  .col {
    background: #fff;
    border-radius: 8px;
    text-align: left;
  }
  .publicColTit {
    ///////////标题行**************
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    padding: 16px 32px;
    margin: 16px 0;
  }
  .publicColW {
    /////////// 大列宽度 **************
    width: 1229px !important;
    margin-right: 16px;
  }
  .publicColW2 {
    /////////// 小列宽度 **************
    box-sizing: border-box;
    width: 440px !important;
    padding: 32px;
  }
  .publicBox {
    ////////////三个小盒子***************
    box-sizing: border-box;
    width: 606px;
    padding: 32px;
    &:nth-child(2) {
      margin: 0 16px;
    }
  }
  .publicThreeBox {
    ////////////三个小盒子***************
    box-sizing: border-box;
    width: 440px;
    padding: 32px;
  }
  .pubColLine {
    display: inline-block;
    width: 1px;
    height: 18px;
    background: #999999;
    margin: -5px 8px;
  }
  .publicTit {
    //各平台服务中的店铺总数
    color: rgba(16, 16, 16, 100);
    font-size: 20px;
  }
  .publicTotal {
    //标题统计
    color: rgba(51, 51, 51, 100);
    font-size: 24px;
    margin-left: 8px;
  }
  /deep/.pubTextBtn {
    span {
      text-decoration: underline;
      color: rgba(24, 144, 255, 100);
      font-size: 20px;
    }
  }
  /////////公用css END//////////

  .active {
    color: #fff !important;
    background-color: #1890ff;
    .iconfont {
      color: #fff !important;
    }
  }
  .iconfont {
    font-size: 24px;
    line-height: 1;
  }

  .nav {
    box-sizing: border-box;
    position: fixed;
    top: 50%;
    right: 6px;
    z-index: 2299;
    transform: translate(0, -50%);
    width: 40px;
    // padding-top: 8px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 8px 0px rgba(24, 144, 255, 0.24);
    cursor: pointer;
    span {
      display: block;
      font-size: 14px;
    }
    .iconfont {
      color: #333;
    }
    li {
      height: 64px;
      // &:nth-child(-n+5){
      //   margin-bottom: 8px;
      // }
      
      &:first-child{
        border-radius: 4px 4px 0px 0px;
      }
      &:last-child{
        border-radius: 0px 0px 4px 4px;
      }
    }
  }

  .row1 {
    display: flex;
    height: 450px;
    .col1-1 {
      box-sizing: border-box;
      padding: 32px 32px 32px;
      height: 55%;
      display: flex;
      // align-items: center;
      .col-left {
        display: flex;
        align-items: center;
        .col1Img {
          box-sizing: border-box;
          width: 120px;
          height: 120px;
          background-color: rgba(255, 255, 255, 100);
          // border: 1px solid rgba(153, 153, 153, 100);
          border-radius: 50%;
          margin-right: 32px;
        }
      }

      .col-middle {
        display: flex;
        align-items: center;
        .midW {
          width: 500px;
        }
        .name {
          display: flex;
          margin-bottom: 12px;
          .ro-name {
            color: rgba(51, 51, 51, 100);
            font-size: 28px;
            font-weight: 700;
            margin-right: 32px;
          }
          .roles {
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            padding: 8px 10px;
            border-radius: 4px;
            border: 1px solid rgba(24, 144, 255, 100);
            color: rgba(24, 144, 255, 100);
            font-size: 16px;
            .role-img {
              width: 16px;
              margin-right: 8px;
            }
          }
        }
        .companyName {
          margin-bottom: 16px;
          color: rgba(16, 16, 16, 100);
          font-size: 18px;
        }
        .wel {
          color: rgba(153, 153, 153, 100);
          font-size: 14px;
          margin-bottom: 12px;
        }
      }
      .col-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 500px;
        .common-input-container {
          color: transparent;
          margin: 0 0 16px;
        }
        .time {
          color: rgba(51, 51, 51, 100);
          font-size: 20px;
          margin-top: 40px;
          margin-bottom: 16px;
        }
        .dateweek {
          color: rgba(51, 51, 51, 100);
          font-size: 18px;
          margin-bottom: 16px;
        }
        .ip {
          color: rgba(153, 153, 153, 100);
          font-size: 14px;
        }
      }
    }
    .col1-2 {
      height: 100%;
      box-sizing: border-box;
      // padding: 32px;
      .head {
        margin-bottom: 32px;
        height: 30px;
        .tit {
          color: rgba(16, 16, 16, 100);
          font-size: 20px;
        }
      }
      /deep/.el-tabs__item {
        border-left: none !important;
      }
      .right-news {
        background-color: #fff;
        width: 100%;
        box-sizing: border-box;
        // margin-bottom: 16px;
        text-align: start;
        border-radius: 8px;
        .news-id {
          display: flex;
          align-items: center;
          &:nth-child(-n + 5) {
            margin-bottom: 24px;
          }
        }
        .news-createtime {
          color: #333333;
          font-size: 14px;
          margin-right: 37px;
          white-space: nowrap;
        }
        .news-username {
          color: #333333;
          font-size: 14px;
          margin-right: 27px;
          display: inline-block;
          width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .news-des {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          width: 90px;
          color: #999999;
        }
      }
      .notice-id {
        align-items: center;
        &:nth-child(-n + 5) {
          margin-bottom: 24px;
        }
      }
      .adtit {
        width: 198px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 37px;
      }
      .report-time{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .col1-3 {
      box-sizing: border-box;
      padding: 24px 32px 32px;
      height: 42%;
      margin-top: 16px;
      .line {
        border-right: 1px solid #bbb;
        padding-right: 197px;
      }
      .work {
        font-size: 20px;
        color: #101010;
      }
      .works {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        color: #333333;
        margin-top: 32px;
        &:nth-child(1) {
          margin-right: 160px;
        }
        /deep/.workNum {
          span {
            font-size: 28px;
            margin-top: 8px;
          }
        }
        .work-red {
          color: #ff4d4f;
          text-decoration: underline;
        }
        .work-blue {
          color: #1890ff;
          text-decoration: underline;
        }
      }
      /deep/ .is-active {
        .el-radio-button__inner {
          border: none;
          color: #1890ff !important;
        }
      }
      .el-radio-button {
        height: 16px;
        &:nth-child(2) {
          border-left: 1px solid #999;
        }
      }
      /deep/.el-radio-button__inner {
        padding: 0 10px;
        border: none;
        background: transparent;
        color: #999999;
        box-shadow: none;
      }
    }
  }
  /deep/.el-tabs__nav-wrap {
    background: #f2f9ff;
  }
  /deep/ .el-tabs__nav {
    width: 100%;
    height: 40px;
    padding: 0 4px;
    line-height: 40px;
    border: none;
  }
  /deep/ .is-active {
    background: #fff;
    background-size: 50%;
  }
  /deep/ .el-tabs__item {
    text-align: center;
    width: 50%;
    height: 30px;
    line-height: 30px;
  }
  /deep/ .el-tabs__header {
    margin-bottom: 32px;
  }
  /deep/ .is-active {
    border: none;
    .el-radio-button__inner {
      border: none;
      color: #1890ff !important;
    }
  }
  .el-radio-button {
    height: 16px;
    &:nth-child(-n + 1) {
      // border-right: 1px solid #999;
    }
  }
  /deep/.el-radio-button__inner {
    padding: 0 10px;
    border: none;
    background: transparent;
    color: #999999;
    box-shadow: none;
  }
  .iconPeople {
    border: 1px solid rgba(153, 153, 153, 100);
    border-radius: 50%;
    overflow: hidden;
    width: 120px;
    height: 120px;
    margin-right: 32px;
    font-size: 120px;
  }
}
</style>
